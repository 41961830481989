import React, { useState, useEffect } from 'react';
import { Edit2, Trash2, Zap, Download, RefreshCw, Check } from 'react-feather';
import './VideoGenerationCard.css';
import api from './api';

const VideoGenerationCard = ({ 
  index, 
  interest, 
  sourceVideo, 
  initialPrompt, 
  onPromptChange, 
  onRemove, 
  onGenerate, 
  creditCost,
  generatedVideoUrl,
  isGenerating,
  error,
  onDownload,
  taskId,
  isFromVideo
}) => {

  console.log('VideoGenerationCard props:', { 
    index, 
    interest, 
    generatedVideoUrl, 
    isFromVideo, 
    taskId 
  });

  const [prompt, setPrompt] = useState(initialPrompt || '');
  const [isEditing, setIsEditing] = useState(false);
  const [isStitching, setIsStitching] = useState(false);
  const [stitchedUrl, setStitchedUrl] = useState(null);

  const initialPromptSetRef = React.useRef(false);
  
  // Update prompt when interest changes, but only once
  useEffect(() => {
    // Only update if we have an interest and haven't set the initial prompt yet
    if (interest && !initialPromptSetRef.current && !isEditing) {
      const newPrompt = ``;
      setPrompt(newPrompt);
      onPromptChange(index, newPrompt);
      initialPromptSetRef.current = true;
    }
  }, [interest, index, onPromptChange, isEditing]);

  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleEditToggle = () => {
    if (isEditing) {
      // When saving, update the parent component
      onPromptChange(index, prompt);
    }
    setIsEditing(!isEditing);
  };

  const handleStitchDownload = async () => {
    try {
      setIsStitching(true);
      
      // Create form data with the task ID
      const formData = new FormData();
      formData.append('taskId', taskId);
      
      const response = await api.stitchVideo(formData);
      setStitchedUrl(response.data.url);

      console.log('Stitched video URL:', stitchedUrl);
      
      // Use the blob approach for downloading
      const videoResponse = await fetch(response.data.url);
      if (!videoResponse.ok) {
        throw new Error(`Failed to fetch video: ${videoResponse.status} ${videoResponse.statusText}`);
      }
      const blob = await videoResponse.blob();
      
      // Create a blob URL
      const blobUrl = URL.createObjectURL(blob);
      
      // Create a download link
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `stitched_video_${Date.now()}.mp4`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      // Clean up the blob URL
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error stitching video:', error);
      alert('Failed to stitch video');
    } finally {
      setIsStitching(false);
    }
  };

  return (
    <div className={`video-generation-card ${isGenerating ? 'generating' : ''} ${error ? 'error' : ''} ${generatedVideoUrl ? 'completed' : ''}`}>
      <div className="card-header">
        <h4>Video {index + 1}</h4>
        
      </div>
      
      <div className="card-interest">
        <h5>Selected Interest:</h5>
        <div className="interest-tag">
          {interest ? (
              <>
                <span className="interest-tag-name">{interest.name}</span><br />
                <span className="interest-tag-size" style={{ fontSize: '10px', opacity: '0.5' }}>{interest.size.toLocaleString()}</span>
              </>
            ) : "No interest selected"}
        </div>
      </div>
      
      <div className="card-prompt" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
        {isEditing ? (
          <textarea
            value={prompt}
            onChange={handlePromptChange}
            placeholder="Enter additional prompt here..."
            rows={3}
          />
        ) : (
          <p onClick={handleEditToggle} style={{fontSize: '12px', cursor: 'pointer'}}>{prompt || "You can add an additional prompt here"}</p>
        )}
        <div className="card-actions">
          <button 
            className="card-action-button" 
            onClick={handleEditToggle}
            title={isEditing ? "Save prompt" : "Edit prompt"}
          >
            {isEditing ? <Check size={16} /> : <Edit2 size={16} />}
          </button>
        </div>
      </div>
      
      {generatedVideoUrl ? (
        <div className="card-video">
          <video 
            src={generatedVideoUrl} 
            controls 
            className="generated-video"
          />
          <div className="video-actions">
            {!isFromVideo && <button 
              className="video-action-button download" 
              onClick={() => onDownload(generatedVideoUrl, `video-${interest}-${index}.mp4`)}
              title="Download video"
            >
              <Download size={16} />
              <span>Download</span>
            </button>}
            {isFromVideo && generatedVideoUrl && (
              <button 
                onClick={handleStitchDownload} 
                disabled={isStitching}
                className="video-action-button download"
              >
                {isStitching && <span className="spinner-border" style={{marginRight:'5px'}}/>}{isStitching ? 'Processing...' : 'Download Full Video'}
              </button>
            )}
            <button 
              className="video-action-button regenerate" 
              onClick={() => onGenerate(index)}
              disabled={isGenerating}
              title="Regenerate video"
            >
              <RefreshCw size={16} className={isGenerating ? 'spin' : ''} />
              <span>{isGenerating ? 'Regenerating...' : 'Regenerate'}</span>
            </button>
          </div>
        </div>
      ) : error ? (
        <div className="card-error">
          <p>{error}</p>
        </div>
      ) : isGenerating ? (
        <div className="card-generating">
          <div className="generating-indicator">
            <div className="spinner"></div>
            <p>Generating video...</p>
          </div>
        </div>
      ) : null}
      
      <div className="card-footer">
        <div className="credit-cost">
          <Zap size={14} />
          <span>{creditCost} credits</span>
        </div>
        
        {!generatedVideoUrl && !isGenerating && (
          <button 
            className="generate-button" 
            onClick={() => onGenerate(index)}
            disabled={!sourceVideo || !interest || isGenerating}
          >
            Generate Video
          </button>
        )}
      </div>
    </div>
  );
};

export default VideoGenerationCard;