import React from 'react';
import { useDarkMode } from './DarkModeContext';

const Settings = () => {
    const { darkMode } = useDarkMode();

    return (
        <div className={`settings-container ${darkMode ? 'dark-mode' : ''}`} style={{ padding: '30px' }}>
            <h1 style={{ marginBottom: '20px' }}>Settings</h1>
            
            <div style={{ 
                background: darkMode ? '#27292D' : 'white', 
                padding: '20px', 
                borderRadius: '8px',
                boxShadow: '0 2px 10px rgba(0,0,0,0.05)',
                marginBottom: '20px'
            }}>
                <h2 style={{ marginBottom: '15px', fontSize: '18px' }}>Account Settings</h2>
                <p style={{ color: '#666' }}>
                Account settings will be available soon. Here you'll be able to manage your profile, 
                subscription, and notification preferences.
                </p>
            </div>
            
            <div style={{ 
                background: darkMode ? '#27292D' : 'white', 
                padding: '20px', 
                borderRadius: '8px',
                boxShadow: '0 2px 10px rgba(0,0,0,0.05)'
            }}>
                <h2 style={{ marginBottom: '15px', fontSize: '18px' }}>API Connections</h2>
                <p style={{ color: '#666', marginBottom: '20px' }}>
                Connect your accounts to enable additional features.
                </p>
                
                <div style={{ 
                display: 'flex', 
                gap: '15px',
                flexWrap: 'wrap'
                }}>
                    <button style={{
                        padding: '10px 15px',
                        background: '#1877F2',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'not-allowed',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px'
                    }} disabled>
                        <span>Connect Meta Business</span>
                    </button>
                
                    {/* <button style={{
                        padding: '10px 15px',
                        background: '#0e76a8',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px'
                    }}>
                        <span>Connect LinkedIn</span>
                    </button> */}
                </div>
            </div>
        </div>
    );
};

export default Settings;