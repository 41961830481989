import React, { useState, useEffect, useRef } from 'react';
import './Workspace.css';
import FeedbackButton from './FeedbackButton';
import CategorySelector from './CategorySelector';
import api from './api';
import { useNavigate } from 'react-router-dom';
import { decodeToken, isTokenExpired } from './utils/jwt';
import { trackEvent } from './utils/amplitude';
import { AlertCircle, Download, ExternalLink, Plus, Zap } from 'react-feather';
import './App.css';
import './MetaModal.css';
import { useWorkspace } from './WorkspaceContext';
import { useDarkMode } from './DarkModeContext';
import VideoGenerationCard from './VideoGenerationCard';
import axios from 'axios';
import categories from './CategoryData';
import { useVideoGeneration } from './VideoGenerationContext';

const Workspace = () => {
    const { darkMode } = useDarkMode();
    // User info
    const [userName, setUserName] = useState('');
    const [userId, setUserId] = useState(null);

    // Profile info
    const [profileComplete, setProfileComplete] = useState(false);
    const [userProfile, setUserProfile] = useState({
        currentSituation: '',
        shortTermGoals: '',
        longTermGoals: ''
    });

    const [userInfo, setUserInfo] = useState({
        picture: '',
        name: ''
    });

    const [showProfileModal, setShowProfileModal] = useState(false);
    const [hasVisited, setHasVisited] = useState(true);

    // Navigation (for redirecting to landing page)
    const navigate = useNavigate();

    const [hasCreatedFirstVideo, setHasCreatedFirstVideo] = useState(false);

    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
    const [isSubscribing, setIsSubscribing] = useState(false);
    const [subscriptionError, setSubscriptionError] = useState('');
    const [isBetaUser, setIsBetaUser] = useState(false);
    const [backendError, setBackendError] = useState('');

    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('info'); // Can be 'info', 'error', or 'success'

    // const [darkMode, setDarkMode] = useState(() => {
    //     // Get initial theme from localStorage, default to light mode
    //     return localStorage.getItem('theme') === 'dark';
    // });

    // const [sourceVideo, setSourceVideo] = useState(null);
    // const [generatedVideoUrl, setGeneratedVideoUrl] = useState('');
    const [generationProgress, setGenerationProgress] = useState(0);
    // const [videoPrompt, setVideoPrompt] = useState('');

    // State for selected categories from the CategorySelector
    // const [selectedCategories, setSelectedCategories] = useState([]);

    const {
        videoPrompt, setVideoPrompt,
        selectedCategories, setSelectedCategories,
        generatedVideoUrl, setGeneratedVideoUrl,
      } = useWorkspace();

    const [showMetaModal, setShowMetaModal] = useState(false);

    // Update theme in localStorage and document when darkMode changes
    // useEffect(() => {
    //     localStorage.setItem('theme', darkMode ? 'dark' : 'light');
    //     document.body.classList.toggle('dark-mode', darkMode);
    // }, [darkMode]);

    // const toggleTheme = () => {
    //     setDarkMode(!darkMode);
    // };

    const [availableInterests, setAvailableInterests] = useState([]);
    const { 
        selectedInterests,
        videoCards,
        analyzing,
        sourceVideo,
        selectedModel,
        credits,
        handleInterestSelect,
        updateCardPrompt,
        generateVideo,
        generateAllVideos,
        downloadVideo,
        setVideo,
        setModel
    } = useVideoGeneration();

    useEffect(() => {
        localStorage.setItem('preferredVideoModel', selectedModel);
    }, [selectedModel]);

    const loadMetaSDK = () => {
        return new Promise((resolve) => {
            // Check if SDK is already loaded
            if (window.FB) return resolve();
            
            window.fbAsyncInit = function() {
                window.FB.init({
                appId: '1341400330503776',
                cookie: true,
                xfbml: true,
                version: 'v18.0'
                });
                resolve();
            };
            
            // Load the SDK asynchronously
            (function(d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        });
    };

    useEffect(() => {
        // Preload the Meta SDK
        loadMetaSDK();
    }, []);


    // Fetch user info on component mount
    useEffect(() => {
        const initializeUser = async () => {
            const params = new URLSearchParams(window.location.search);
            const tokenFromUrl = params.get('token');
            let token = tokenFromUrl || localStorage.getItem('token');

            console.log("params:", params);
            console.log("tokenFromUrl:", tokenFromUrl);
            console.log('Token from localStorage:', localStorage.getItem('token').substring(0, 10));
            console.log("token:", token.substring(0, 10));

            if (tokenFromUrl) {
                // Save the token to local storage
                localStorage.setItem('token', tokenFromUrl);
                console.log('Token saved to localStorage:', tokenFromUrl);
                
                // Clean up the URL to remove the token
                window.history.replaceState({}, document.title, window.location.pathname);
            }

            // Validate the token
            if (!token || isTokenExpired(token)) {
                console.log('Token is invalid or expired. Redirecting to landing page.');
                navigate('/');
                return;
            }

            // Decode the token to get the user ID and fetch files
            const decoded = decodeToken(token);
            console.log('decoded Token:', decoded);
            console.log('decoded username:', decoded.name);
            console.log('decoded ID:', decoded.userId);

            if (decoded) {
                setUserId(decoded.userId); // Set the user ID
                setUserName(decoded.name); // Extract the name from the token
                setUserInfo(prev => ({ ...prev, name: decoded.name }));

                try {
                    setBackendError(''); // Clear any previous error

                    // Fetch profile
                    const profileResponse = await api.getUserProfile();
                    console.log('Profile response:', profileResponse.data);

                    if (profileResponse.data) {
                        setUserProfile({
                            ...profileResponse.data.profile,
                            hasCreatedFirstVideo: profileResponse.data.hasCreatedFirstVideo,
                            isBetaUser: profileResponse.data.isBetaUser,
                            isSubscribed: profileResponse.data.isSubscribed,
                            stripeCustomerId: profileResponse.data.stripeCustomerId,
                            subscriptionStatus: profileResponse.data.subscriptionStatus
                        });

                        setProfileComplete(profileResponse.data.profileComplete);
                        setHasVisited(profileResponse.data.hasVisited);
                        setUserInfo(prev => ({
                            ...prev,
                            picture: profileResponse.data.picture,
                            name: profileResponse.data.name || decoded.name
                        }));
                        
                        if (!profileResponse.data.hasVisited) {
                            setShowProfileModal(true);
                            await api.markVisited();
                        }
                    }

                } catch (error) {
                    console.error('Error initializing:', error);
                    setBackendError('Hey people, Boukar here, I was coding and did some shit, server down, will fix asap, sorry for that, thanks for using the app (help!)');
                    if (error.response?.status === 401) {
                        navigate('/');
                    }
                }
            } else {
                setBackendError('Hey people, Boukar here, I was coding and did some shit, server down, will fix asap, sorry for that, thanks for using the app (help!)');//setBackendError('Unable to load data, server down, please try again later');
            }
        };

        initializeUser();
    }, [navigate]);

    // Profile Modal Component
    const ProfileModal = () => {
        // État local pour le formulaire
        const [formData, setFormData] = useState({
            currentSituation: userProfile.currentSituation || '',
            shortTermGoals: userProfile.shortTermGoals || '',
            longTermGoals: userProfile.longTermGoals || ''
        });

        const handleSave = async () => {
            try {
                setShowProfileModal(false);
                trackEvent('profile_update_started', {
                    userId,
                    fieldsUpdated: Object.keys(formData).filter(key => formData[key].trim() !== '')
                });

                // Structure the data correctly for the API
                const profileData = {
                    profile: {
                        currentSituation: formData.currentSituation,
                        shortTermGoals: formData.shortTermGoals,
                        longTermGoals: formData.longTermGoals
                    }
                };

                const response = await api.updateProfile(profileData);

                trackEvent('profile_update_successful', {
                    userId,
                    profileComplete: response.data.profileComplete
                });

                // Update userProfile state with the nested structure
                setUserProfile(prev => ({
                    ...prev,
                    ...response.data.profile
                }));
                setProfileComplete(response.data.profileComplete);
            } catch (error) {
                trackEvent('profile_update_error', {
                    error: error.message,
                    userId
                });
                console.error('Error saving profile:', error);
            }
        };

        const handleManageSubscription = async () => {
            try {
                const response = await api.createPortalSession();
                if (response.data.url) {
                    window.open(response.data.url, '_blank');
                }
            } catch (error) {
                console.error('Error opening subscription portal:', error);
                // Optionally show an error message to the user
            }
        };

        const handleLogout = () => {
            // Clear local storage
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            
            // Track logout event
            trackEvent('user_logged_out', { userId });
            
            // Redirect to home
            window.location.href = '/';
        };

        return (
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0,0,0,0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000
            }}>
                <div style={{
                    backgroundColor: darkMode ? '#27292D' : 'white',
                    padding: '30px',
                    borderRadius: '10px',
                    width: '90%',
                    maxWidth: '600px',
                    maxHeight: '90vh',
                    overflowY: 'auto'
                }}>
                    <h2>Hi {(userInfo.name || userName)?.split(' ')[0]}!</h2>
                    <button
                        onClick={handleLogout}
                        style={{
                            padding: '8px 16px',
                            borderRadius: '5px',
                            // backgroundColor: '#f0f0f0',
                            border: darkMode ? '1px solid #444' : '1px solid #dfe1e5',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            color: '#8B0000' //'#dc3545'  // Red color for logout
                        }}
                    >
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                            <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                            <polyline points="16 17 21 12 16 7" />
                            <line x1="21" y1="12" x2="9" y2="12" />
                        </svg>
                        Logout
                    </button>
                    {!userProfile.isSubscribed && (
                        <button
                            onClick={handleManageSubscription}
                            style={{
                                padding: '8px 16px',
                                borderRadius: '5px',
                                // backgroundColor: '#f0f0f0',
                                border: darkMode ? '1px solid #444' : '1px solid #dfe1e5',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                                marginTop: '10px'
                            }}
                        >
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                <path d="M3 10h18M7 15h.01M11 15h.01M15 15h.01M19 15h.01M5 20h14a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2z"/>
                            </svg>
                            Manage Subscription
                        </button>
                    )}
                    <h2>Tell us more about you</h2>
                    <p style={{ color: '#666', marginBottom: '20px' }}>
                        This information helps us provide better personalized assistance
                    </p>
                    {!hasVisited && (
                        <p style={{ color: '#007bff', marginBottom: '20px' }}>
                            You can change it whenever.
                        </p>
                    )}

                    <div style={{ marginBottom: '20px' }}>
                        <label style={{ display: 'block', marginBottom: '5px' }}>
                            Current Situation
                        </label>
                        <textarea
                            value={formData.currentSituation}
                            onChange={(e) => setFormData(prev => ({
                                ...prev,
                                currentSituation: e.target.value
                            }))}
                            placeholder="Your current job, skills, experience..."
                            style={{
                                width: '100%',
                                padding: '10px',
                                borderRadius: '5px',
                                border: darkMode ? '1px solid #444' : '1px solid #dfe1e5',
                                minHeight: '100px',
                                fontFamily: 'inherit'
                            }}
                        />
                    </div>

                    <div style={{ marginBottom: '20px' }}>
                        <label style={{ display: 'block', marginBottom: '5px' }}>
                            Short Term Goals
                        </label>
                        <textarea
                            value={formData.shortTermGoals}
                            onChange={(e) => setFormData(prev => ({
                                ...prev,
                                shortTermGoals: e.target.value
                            }))}
                            placeholder="Goals for the next 3-6 months..."
                            style={{
                                width: '100%',
                                padding: '10px',
                                borderRadius: '5px',
                                border: darkMode ? '1px solid #444' : '1px solid #dfe1e5',
                                minHeight: '100px',
                                fontFamily: 'inherit'
                            }}
                        />
                    </div>

                    <div style={{ marginBottom: '20px' }}>
                        <label style={{ display: 'block', marginBottom: '5px' }}>
                            Long Term Goals
                        </label>
                        <textarea
                            value={formData.longTermGoals}
                            onChange={(e) => setFormData(prev => ({
                                ...prev,
                                longTermGoals: e.target.value
                            }))}
                            placeholder="Where do you see yourself in 1-5 years..."
                            style={{
                                width: '100%',
                                padding: '10px',
                                borderRadius: '5px',
                                border: darkMode ? '1px solid #444' : '1px solid #dfe1e5',
                                minHeight: '100px',
                                fontFamily: 'inherit'
                            }}
                        />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                        <button
                            onClick={() => setShowProfileModal(false)}
                            style={{
                                padding: '8px 16px',
                                borderRadius: '5px',
                                border: darkMode ? '1px solid #444' : '1px solid #dfe1e5',
                                cursor: 'pointer'
                            }}
                        >
                            Close
                        </button>
                        <button
                            onClick={handleSave}
                            className="main-button"
                        >
                            Save Profile
                        </button>
                    </div>

                    
                </div>
            </div>
        );
    }

    // // Handle category selection from the CategorySelector component
    // const handleCategorySelect = (selectedCategories) => {
    //     setSelectedCategories(selectedCategories);

    //     // Update the currently selected card with these interests
    //     updateCardInterests(selectedCardIndex, selectedCategories);
        
    //     // You can use the selected categories for further processing
    //     console.log('Selected categories:', selectedCategories);
    // };

    // const handleFileChange = (e) => {
    //     if (e.target.files && e.target.files[0]) {
    //         setSourceVideo(e.target.files[0]);
    //     }
    // };

    // const handlePromptChange = (e) => {
    //     setVideoPrompt(e.target.value);
    // };

    // const handleVideoGeneration = async () => {
    //     console.log('handleVideoGeneration called');
    //     if (!sourceVideo) {
    //         setMessage('Please upload a source video first');
    //         setMessageType('error');
    //         return;
    //     }

    //     // if (!videoPrompt.trim()) {
    //     //     setMessage('Please provide a prompt for the video generation');
    //     //     return;
    //     // }

    //     setAnalyzing(true);
    //     setMessage('Analyzing video and applying selected interests...');
    //     setMessageType('info');
    //     setGenerationProgress(10); // Start progress
        
    //     try {
    //         // Create a FormData object to send the file and prompt
    //         const formData = new FormData();

    //         // Make sure to use the correct field name that the server expects
    //         formData.append('sourceImage', sourceVideo);

    //         // Add selected interests to the request - convert to simple array of strings
    //         const interestNames = selectedCategories.map(cat => 
    //             typeof cat === 'string' ? cat : cat.name?.split(' (')[0] || cat.name || cat
    //         );
    //         formData.append('interests', JSON.stringify(interestNames));
    //         console.log('Interest names:', interestNames);
            
    //         // Add the text prompt if provided
    //         if (videoPrompt.trim()) {
    //             formData.append('prompt', videoPrompt);
    //         }

    //         // Parse the selected model
    //         if (selectedModel === 'runway') {
    //             formData.append('model', 'runway');
    //         } else if (selectedModel.startsWith('luma-')) {
    //             formData.append('model', 'luma');
    //             formData.append('lumaModel', selectedModel.replace('luma-', ''));
    //         }

    //         console.log('Sending request with:', {
    //             fileSize: sourceVideo.size,
    //             fileName: sourceVideo.name,
    //             interests: interestNames,
    //             prompt: videoPrompt,
    //             model: selectedModel,
    //             lumaModel: selectedModel.startsWith('luma-') ? selectedModel.replace('luma-', '') : undefined
    //         });
        
    //         // Start the video generation
    //         const response = await api.transformVideo(formData);
    //         const taskId = response.data.taskId;
            
    //         setMessage('Video generation in progress. This may take a few minutes...');
    //         setMessageType('info')
            
    //         // Poll for status updates
    //         const statusCheckInterval = setInterval(async () => {
    //             try {
    //                 const statusResponse = await api.checkTransformVideoStatus(taskId);
                    
    //                 if (statusResponse.data.status === 'completed') {
    //                     clearInterval(statusCheckInterval);
    //                     setGeneratedVideoUrl(statusResponse.data.videoUrl);
    //                     setGenerationProgress(100);
    //                     setMessage('Video transformed successfully with your selected interests!');
    //                     setMessageType('success')
    //                     setAnalyzing(false);

    //                     // Play the success sound
    //                     successAudio.play().catch(e => {
    //                         console.warn('Could not play success sound:', e);
    //                     });

    //                     const creditCost = selectedModel === 'runway' ? 40 : 80;
    //                     updateCreditsAfterGeneration(creditCost);

    //                 } else if (statusResponse.data.status === 'failed') {
    //                     clearInterval(statusCheckInterval);
    //                     setMessage(`Error: ${statusResponse.data.error}`);
    //                     setMessageType('error')
    //                     setGenerationProgress(0);
    //                     setAnalyzing(false);
    //                 } else {
    //                     // Update progress for ongoing task
    //                     setGenerationProgress(prev => {
    //                         const newProgress = prev + 2;
    //                         return newProgress > 90 ? 90 : newProgress;
    //                     });
    //                 }
    //             } catch (error) {
    //                 console.error('Error checking status:', error);
    //                 // Don't clear the interval, just log the error and continue polling
    //             }
    //         }, 5000); // Check every 5 seconds
            
    //     } catch (error) {
    //         console.error('Error generating video:', error);
            
    //         // Parse the error message to provide more context
    //         let errorMessage = 'Error generating video. Please try again.';
            
    //         if (error.response && error.response.data) {
    //             const errorData = error.response.data;
                
    //             if (errorData.error && errorData.error.includes('Invalid asset aspect ratio')) {
    //                 errorMessage = 'The image has an invalid aspect ratio. Please use an image with width/height ratio between 0.5 and 2.';
    //             } else if (errorData.error) {
    //                 errorMessage = errorData.error;
    //             }
    //         } else if (error.message) {
    //             errorMessage = error.message;
    //         }
            
    //         setMessage(errorMessage);
    //         setMessageType('error');
    //         setGenerationProgress(0);
    //         setAnalyzing(false);
    //     }
    // };

    const [isMuted, setIsMuted] = useState(() => {
        const savedMuteState = localStorage.getItem('musicMuted');
        return savedMuteState ? JSON.parse(savedMuteState) : false;
    });
    
    // Create audio element with the saved mute state
    const [audio] = useState(() => {
        const audioElement = new Audio('/Girl from Petaluma-2.mp3');
        audioElement.volume = 0.5; // Set volume to 50%
        audioElement.loop = true; // Make it loop
        
        // Apply the initial mute state
        const savedMuteState = localStorage.getItem('musicMuted');
        audioElement.muted = savedMuteState ? JSON.parse(savedMuteState) : false;
        
        return audioElement;
    });

    // const [successAudio] = useState(() => {
    //     const audioElement = new Audio('/success-sound.mp3');
    //     audioElement.volume = 0.7; // Set volume to 70%
    //     return audioElement;
    // });

    useEffect(() => {
        if (analyzing) {
            audio.play().catch(error => {
                console.error('Audio playback failed:', error);
            });
        } else {
            audio.pause();
            audio.currentTime = 0;
        }

        // Cleanup function
        return () => {
            audio.pause();
            audio.currentTime = 0;
        };
    }, [analyzing, audio]);

    // Effect to update localStorage when mute state changes
    useEffect(() => {
        localStorage.setItem('musicMuted', JSON.stringify(isMuted));
        audio.muted = isMuted;
    }, [isMuted, audio]);

    // Toggle mute function
    const toggleMute = () => {
        setIsMuted(prevMuted => !prevMuted);
    };

    const calculateYearProgress = () => {
        const now = new Date();
        const start = new Date(now.getFullYear(), 0, 0); // Début de l'année
        const diff = now - start;
        const oneDay = 1000 * 60 * 60 * 24;
        const dayOfYear = Math.floor(diff / oneDay);
        const progress = (dayOfYear / 365) * 100;
        return progress.toFixed(1); // Retourne le pourcentage avec 1 décimale
    };

    const handleDownloadVideo = async () => {
        console.log('handleDownloadVideo called');
        try {
            // Show a loading message
            setMessage('Downloading video...');
            setMessageType('info')
            console.log('Downloading video...');
            
            // Get the video element and its source
            const videoElement = document.querySelector('.result-container video');
            console.log('videoElement:', videoElement);
            
            // Get the source element inside the video
            const sourceElement = videoElement?.querySelector('source');
            const videoSrc = sourceElement?.src || videoElement?.src || generatedVideoUrl;
            
            console.log('videoSrc:', videoSrc);
            
            if (!videoSrc) {
                setMessage('Video source not found');
                setMessageType('error')
                return;
            }
            
            // Create a blob from the video source
            const response = await fetch(videoSrc);
            console.log('response:', response);
            const blob = await response.blob();
            console.log('blob:', blob);
            
            // Create a URL for the blob
            const blobUrl = URL.createObjectURL(blob);
            console.log('blobUrl:', blobUrl);
            
            // Create a download link
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = `generated-video-${Date.now()}.mp4`;
            console.log('link:', link);
            
            // Trigger the download
            document.body.appendChild(link);
            console.log('document.body.appendChild(link);');
            link.click();
            console.log('link.click();');
            document.body.removeChild(link);
            console.log('document.body.removeChild(link);');
            
            // Clean up the blob URL
            URL.revokeObjectURL(blobUrl);
            console.log('URL.revokeObjectURL(blobUrl);');
            
            // Show success message and modal
            setMessage('Video downloaded successfully!');
            setMessageType('success')
            setShowMetaModal(true);
        } catch (error) {
            console.error('Error downloading video:', error);
            setMessage('Error downloading video. Please try again.');
            setMessageType('error')
        }
    };

    // Add this function to handle the Meta redirect
    const handleMetaRedirect = () => {
        // Open Meta Business Suite in a new tab
        window.open('https://business.facebook.com/latest/content_calendar', '_blank');
        
        // Close the modal
        setShowMetaModal(false);
        
        // Show a helpful message
        setMessage('Meta Business Suite opened in a new tab. Upload your video there to create a campaign.');
    };

    // Extract interests from categories data
    useEffect(() => {
        const extractInterests = () => {
        const interests = [];
        
        // Function to recursively extract interests from category tree
        const processCategory = (category) => {
            // If it's a leaf node with a size, it's an interest
            if (category.size && !category.children) {
            // Keep both name and size
            interests.push({
                name: category.name.split(' (')[0], // Name without parentheses
                fullName: category.name, // Full name with parentheses
                size: category.size // Size value
                });
            }
            
            // Process children if they exist
            if (category.children && Array.isArray(category.children)) {
                category.children.forEach(processCategory);
            }
        };
        
        // Process all top-level categories
        categories.forEach(processCategory);
        
        // Remove duplicates (based on name) and sort by size (descending)
        const uniqueInterests = [];
        const seen = new Set();
        
        interests.forEach(interest => {
            if (!seen.has(interest.name)) {
            seen.add(interest.name);
            uniqueInterests.push(interest);
            }
        });
        
        return uniqueInterests.sort((a, b) => b.size - a.size);
        };
        
        setAvailableInterests(extractInterests());
    }, []);

      // Handle file upload
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setVideo(file);
        }
    };
    
    // Handle model selection
    const handleModelChange = (event) => {
        setModel(event.target.value);
    };

    return (
        <div className="workspace">
            {/* Header */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '30px' }}>
                <h1>Merra.ai</h1>
                <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                    {/* <div className="theme-toggle" style={{marginTop:'20px'}}>
                        <span className="toggle-label">Dark mode</span>
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={darkMode}
                                onChange={toggleTheme}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div> */}
                    {userProfile.isSubscribed && (
                        <span style={{
                            backgroundColor: '#000000',
                            border: '1px solid #FFD700',
                            color: '#FFD700',
                            padding: '4px 8px',
                            borderRadius: '4px',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            letterSpacing: '0.5px'
                        }}>
                            PRO
                        </span>
                    )}
                    <button 
                        className="profile-button"
                        onClick={() => setShowProfileModal(true)}
                        style={{
                            width: '32px',
                            height: '32px',
                            borderRadius: '50%',
                            border: 'none',
                            cursor: 'pointer',
                            position: 'relative',
                            padding: 0,
                            backgroundColor: userInfo.picture ? 'transparent' : '#27292D',
                        }}
                    >
                        {userInfo.picture ? (
                            <img 
                                src={userInfo.picture} 
                                alt="Profile" 
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderRadius: '50%'
                                }}
                            />
                        ) : (
                            <i className="fas fa-user" style={{ color: 'white' }}></i>
                        )}

                        {/* Notification Dot */}
                        {!profileComplete && (
                            <span style={{
                                position: 'absolute',
                                top: '-5px',
                                right: '-5px',
                                width: '12px',
                                height: '12px',
                                backgroundColor: '#ff4444',
                                borderRadius: '50%',
                                border: '2px solid white',
                            }} />
                        )}
                    </button>
                </div>
            </div>

            {/* error banner */}
            {backendError && (
                <div style={{
                    backgroundColor: '#ff44444d',
                    color: '#ff4444',
                    padding: '12px 20px',
                    borderRadius: '8px',
                    marginBottom: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    border: '1px solid #ff4444',
                    maxWidth: '500px',
                    alignSelf: 'center',
                    justifySelf: 'center',
                }}>
                    <AlertCircle size={20} />
                    {backendError}
                </div>
            )}

            {/* Profile Modal */}
            {showProfileModal && <ProfileModal />}

            {/* Greeting */}
            <div style={{ textAlign: 'center', marginBottom: '30px' }}>
                <h2>Hello {userName?.split(' ')[0]}!</h2>
                <h2 className={darkMode ? "gradient-text-dark" : "gradient-text"} style={{justifySelf:"center", fontSize:'20px'}}>
                    Feeling awesome today?
                </h2>
            </div>
        
            <div className="video-generation-container">
                <h3>Video Generation</h3>
                
                <div className="input-container">
                    <label htmlFor="source-video">Upload file (video or image):</label>
                    
                    {sourceVideo ? (
                        <div className="selected-file-container">
                            <div className="selected-file-info">
                                <span className="file-icon">🖼️</span>
                                <span className="file-name">{sourceVideo.name}</span>
                            </div>
                            <div className="file-actions">
                                <button 
                                    className="file-action-button replace"
                                    onClick={() => document.getElementById('source-video').click()}
                                    title="Replace file"
                                >
                                    Replace
                                </button>
                                <button 
                                    className="file-action-button clear"
                                    onClick={() => setVideo(null)}
                                    title="Clear selection"
                                >
                                    Clear
                                </button>
                            </div>
                        </div>
                    ) : (
                        <input
                            type="file"
                            id="source-video"
                            accept="video/*, image/*"
                            onChange={handleFileUpload}
                            style={{fontSize:'14px'}}
                        />
                    )}
                    
                    {/* Hidden input for replacing the file */}
                    <input
                        type="file"
                        id="source-video"
                        accept="video/*, image/*"
                        onChange={handleFileUpload}
                        style={{ display: 'none' }}
                    />
                </div>
                
                {/* <div className="video-prompt-container">
                    <label htmlFor="video-prompt">Transform your video</label>
                    <div className="textarea-wrapper">
                        <textarea
                            id="video-prompt"
                            value={videoPrompt}
                            onChange={handlePromptChange}
                            placeholder="Describe how you want to transform the video (e.g., 'Convert to anime style' or 'Make it look like it's from the 1920s')"
                            rows={3}
                        />
                        {videoPrompt && (
                            <button 
                                className="clear-button" 
                                onClick={() => setVideoPrompt('')}
                                title="Clear text"
                            >
                                ×
                            </button>
                        )}
                    </div>
                    <div className="prompt-examples">
                        <p>Examples:</p>
                        <div className="example-chips">
                            <button onClick={() => setVideoPrompt('Convert this video to anime style')}>Anime style</button>
                            <button onClick={() => setVideoPrompt('Make this video look like it\'s from the 1920s')}>1920s vintage</button>
                            <button onClick={() => setVideoPrompt('Add a cinematic color grade to this video')}>Cinematic</button>
                            <button onClick={() => setVideoPrompt('Convert to black and white film noir style')}>Film noir</button>
                        </div>
                    </div>
                </div> */}

                <div className="workspace-section">
                    <h3>Select Interests (Max 5)</h3>
                    <p className="section-description">
                        Each interest will generate one video. You can select up to 5 interests, to generate up to 5 videos.
                    </p>
                    
                    <div className="interests-grid">
                        {availableInterests.map((interest, index) => (
                        <div 
                            key={index}
                            className={`interest-item ${selectedInterests.some(i => i.name === interest.name) ? 'selected' : ''}`}
                            onClick={() => handleInterestSelect(interest)}
                        >
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <span className="interest-name">
                                    {interest.name} 
                                    {selectedInterests.some(i => i.name === interest.name) && (
                                    <span className="interest-selected-indicator"> ✓</span>
                                    )}
                                </span>
                                <br />
                                <span className="interest-size" style={{ fontSize: '10px', opacity: '0.5', whiteSpace: 'nowrap' }}>{interest.size.toLocaleString()}</span>
                            </div>
                        </div>
                        ))}
                    </div>
                </div>

                <div className="model-selection" style={{ marginBottom: '15px', marginTop: '30px', position: 'relative', zIndex: '1000' }}>
                    <label htmlFor="model-select" style={{ 
                        display: 'block', 
                        marginBottom: '8px', 
                        fontWeight: 'bold' 
                    }}>
                        Select Video Generation Model:
                    </label>
                    
                    <select 
                        id="model-select"
                        value={selectedModel}
                        onChange={handleModelChange}
                        style={{
                            padding: '8px 12px',
                            borderRadius: '4px',
                            border: '1px solid #ddd',
                            backgroundColor: darkMode ? '#27292D' : 'white',
                            fontSize: '12px',
                            minWidth: '200px',
                            cursor: 'pointer'
                        }}
                    >
                        <option value="runway">Runway (Standard) - 40 credits per video</option>
                        <option value="luma-ray-flash-2">Luma Ray Flash 2 (Latest) - 80 credits per video</option>
                        <option value="luma-photon">Luma Ray Flash 2 + Photon Flash 1 - 80 credits per video</option>
                        {/* <option value="luma-ray-1.6">Luma Ray 1.6 (Classic) - 80 credits per video</option> */}
                    </select>
                    
                    <p style={{ 
                        fontSize: '13px', 
                        color: '#666', 
                        marginTop: '8px',
                        fontStyle: 'italic'
                    }}>
                        {selectedModel === 'runway' 
                            ? 'Runway provides standard quality video generation with faster processing times. (40 credits per video)' 
                            : selectedModel === 'luma-ray-flash-2'
                                ? 'Luma Ray Flash 2 offers the highest quality video generation with the latest AI model. (80 credits per video)'
                                : 'Luma Ray + Photon offers a better customizability. (80 credits per video)'/*'Luma Ray 1.6 offers high quality video generation with the classic AI model. (80 credits per video)'*/
                        }
                    </p>
                </div>

                    {/* Add this section for video generation */}
                    <div className="workspace-section">
                    <div className="section-header">
                        <h3>Generate Videos</h3>
                        <div className="credit-info">
                        <span>
                            Cost per video with this model: {selectedModel === 'runway' ? '40' : '80'} credits
                        </span>
                        <span>
                            Total cost: {videoCards.length * (selectedModel === 'runway' ? 40 : 80)} credits
                        </span>
                        </div>
                    </div>
                    
                    {videoCards.length > 0 ? (
                        <>
                        <div className="video-cards-container">
                            {videoCards.map((card, index) => (
                            <VideoGenerationCard
                                key={index}
                                index={index}
                                interest={card.interest}
                                sourceVideo={sourceVideo}
                                initialPrompt={card.prompt}
                                onPromptChange={updateCardPrompt}
                                onGenerate={generateVideo}
                                creditCost={selectedModel === 'runway' ? 40 : 80}
                                generatedVideoUrl={card.generatedVideoUrl}
                                isGenerating={card.isGenerating}
                                error={card.error}
                                onDownload={downloadVideo}
                                isFromVideo={card.isFromVideo}
                                taskId={card.taskId}
                            />
                            ))}
                        </div>
                        
                        {!analyzing && (
                            <button 
                                className='waitlist-button'
                                style={{
                                    color: '#333333',
                                    margin: '20px 0',
                                    borderWidth: '0',
                                    position: 'relative',
                                    cursor: (analyzing || !sourceVideo) ? 'not-allowed' : 'pointer',
                                    opacity: (analyzing || !sourceVideo) ? 0.7 : 1,
                                    justifySelf: 'center',
                                    display: 'flex'
                                }}
                                onClick={generateAllVideos}
                                disabled={analyzing || !sourceVideo || videoCards.length === 0}
                            >
                                
                                <span>{analyzing ? <><span className="spinner-border spinner-border-sm" />{' '}Generating...</> : `Generate The Videos (${videoCards.length * (selectedModel === 'runway' ? 40 : 80)} credits)`}</span>
                                <span className="waitlist-shimmer"></span>
                            </button>
                        )}
                        
                        {analyzing && (
                            <div className="analyzing-indicator">
                            <div className="spinner"></div>
                            <p>Generating videos... Please wait</p>
                            </div>
                        )}
                        </>
                    ) : (
                        <div className="no-interests-selected">
                        <p>Select at least one interest to generate a video</p>
                        </div>
                    )}
                </div>
                {!sourceVideo  && (
                    <div style={{textAlign: 'center', marginBottom: '15px'}}>
                        <p className="message" style={{fontSize: '14px'}}>Please upload a source video to generate videos</p>
                    </div>
                )}
                
                {message && (
                    <div className={`message-container ${messageType}`}>
                        {messageType === 'error' && <AlertCircle size={18} />}
                        <p className="message">{message}</p>
                    </div>
                )}
                
                {/* {generatedVideoUrl && (
                    <div className="result-container">
                        <h4>Generated Video:</h4>
                        <video controls width="100%">
                            <source src={generatedVideoUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <div className="video-actions" style={{ 
                            display: 'flex', 
                            justifyContent: 'center', 
                            gap: '10px',
                            marginTop: '15px' 
                        }}>
                            <button
                                onClick={handleDownloadVideo}
                                className="gradient-button"
                                style={{
                                    padding: '8px 16px',
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                    fontSize: '12px'
                                }}
                            >
                                <Download size={16}/>
                                Download Video
                            </button>
                            <a
                                href={generatedVideoUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="purple-button"
                                style={{
                                    padding: '8px 16px',
                                    color: 'white',
                                    backgroundColor:'#5C4BD3',
                                    border: 'none',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                    fontSize: '12px',
                                    textDecoration: 'none'
                                }}
                            >
                                <ExternalLink size={16}/>
                                View in New Tab
                            </a>
                        </div>
                    </div>
                )} */}
            </div> 

            {analyzing && (
                <div>
                    <div className="shimmer" style={{textAlign: 'center', marginBottom: '15px', display: 'flex', justifyContent: 'center'}}>
                        <img src="/dancing-happy-dance.gif" alt="loading" style={{width: '90px', height: '90px', alignSelf: 'center', marginTop: '20px', borderRadius: '5%'}}/>
                    </div>
                    <br/>
                    {/* Add mute/unmute button */}
                    <button 
                        onClick={toggleMute}
                        style={{
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '14px',
                            alignSelf: 'center',
                            justifySelf: 'center',
                            marginTop: '20px',
                            marginBottom: '20px',
                            color: '#0066cc',
                            textDecoration: 'underline',
                        }}
                    >
                        {isMuted ? (
                            <>
                                <span style={{marginRight: '5px'}}>🔇</span> Unmute Music
                            </>
                        ) : (
                            <>
                                <span style={{marginRight: '5px'}}>🔊</span> Mute Music
                            </>
                        )}
                    </button>
                    <div className="generation-progress">
                        <div className="progress-bar-container">
                            <div 
                                className="progress-bar-fill" 
                                style={{width: `${generationProgress}%`}}
                            ></div>
                        </div>
                        <p className="progress-text">
                            {generationProgress < 30 ? 'Analyzing video content...' : 
                            generationProgress < 60 ? 'Applying selected interests...' : 
                            'Finalizing your transformed video...'}
                        </p>
                    </div>
                </div>
            )}

            {/* Year Progress Bar */}
            <div style={{
                padding: '20px',
                marginTop: '30px',
                marginBottom: '30px',
                borderTop: darkMode ? '1px solid #222529' : '1px solid #666',
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '8px',
                    // color: '#ffffff95',
                    opacity: '0.8',
                    fontSize: '14px'
                }}>
                    <span>Year {new Date().getFullYear()} progression (reminder to do the most)</span>
                    <span>{calculateYearProgress()}% complete</span>
                </div>
                <div style={{
                    width: '100%',
                    height: '4px',
                    backgroundColor: darkMode ? '#2A2B2F' : '#214B23',
                    borderRadius: '2px',
                    overflow: 'hidden'
                }}>
                    <div style={{
                        width: `${calculateYearProgress()}%`,
                        height: '100%',
                        backgroundColor: '#4CAF50',
                        borderRadius: '2px',
                        transition: 'width 0.3s ease'
                    }}/>
                </div>
            </div>

            {showMetaModal && (
                <div className="meta-modal-overlay" style={{color: 'black'}}>
                    <div className="meta-modal">
                        <div className="meta-modal-header">
                            <h3>Share Your Video on Meta</h3>
                            <button 
                                className="meta-modal-close" 
                                onClick={() => setShowMetaModal(false)}
                            >
                                &times;
                            </button>
                        </div>
                        <div className="meta-modal-body">
                            <p>Your video has been downloaded successfully!</p>
                            <p>Would you like to upload it to Meta Business Suite to create an ad campaign?</p>
                            
                            <div className="meta-modal-image">
                                <img 
                                    src="/meta-business-suite-logo.png" 
                                    alt="Meta Business Suite" 
                                    style={{ maxWidth: '150px', marginBottom: '15px'}}
                                />
                            </div>
                            
                            <div className="meta-modal-steps">
                                <p><strong>Quick steps:</strong></p>
                                <ol>
                                    <li>Click the button below to open Meta Business Suite</li>
                                    <li>Go to "Content" or "Create Ad"</li>
                                    <li>Upload the video you just downloaded</li>
                                    <li>Follow the prompts to create your campaign</li>
                                </ol>
                            </div>
                        </div>
                        <div className="meta-modal-footer">
                            <button 
                                className="meta-modal-secondary" 
                                onClick={() => setShowMetaModal(false)}
                            >
                                Maybe Later
                            </button>
                            <button 
                                className="meta-modal-primary" 
                                onClick={handleMetaRedirect}
                            >
                                Open Meta Business Suite
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Feedback Button */}
            <FeedbackButton username={userName} />
        </div>
    );
};

export default Workspace;