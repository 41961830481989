import React, { createContext, useState, useContext, useEffect } from 'react';
import api from './api';

const CreditContext = createContext();

export const CreditProvider = ({ children }) => {
    const [credits, setCredits] = useState({
        used: 0,
        total: 1600,
        loading: true,
        error: null
    });

    const fetchCredits = async () => {
        try {
        setCredits(prev => ({ ...prev, loading: true, error: null }));
        const response = await api.getUserCredits();
        setCredits({
            used: response.data.used || 0,
            total: response.data.total || 1600,
            loading: false,
            error: null
        });
        } catch (error) {
        console.error('Error fetching credits:', error);
        setCredits(prev => ({
            ...prev,
            loading: false,
            error: 'Failed to load credits'
        }));
        }
    };

    // Add this function to handle adding credits
    const addCredits = async (amount = 500) => {
        try {
        // Update credits locally first for immediate feedback
        const newCreditsUsed = Math.max(0, credits.used - amount);
        const newCredits = {
            ...credits,
            used: newCreditsUsed,
            remaining: credits.total - newCreditsUsed
        };
        setCredits(newCredits);
        
        // Then send the request to the server
        const response = await api.addCredits({ amount });
        
        // Update with the server response to ensure accuracy
        if (response.data) {
            setCredits({
            total: response.data.total || 1600,
            used: response.data.used || 0,
            remaining: response.data.remaining || 1600
            });
        }
        } catch (error) {
        console.error('Error adding credits:', error);
        // Revert the local change if there was an error
        fetchCredits();
        }
    };

    // Fetch credits on initial load
    useEffect(() => {
        fetchCredits();
    }, []);

    // Function to update credits after a generation
    const updateCreditsAfterGeneration = (creditsUsed = 1) => {
        setCredits(prev => ({
        ...prev,
        used: Math.min(prev.used + creditsUsed, prev.total)
        }));
    };

    return (
        <CreditContext.Provider value={{ 
            credits, 
            fetchCredits, 
            updateCreditsAfterGeneration,
            addCredits
        }}>
        {children}
        </CreditContext.Provider>
    );
};

export const useCredits = () => useContext(CreditContext);