import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';
import { trackEvent } from './utils/amplitude'; // Import the trackEvent function
import { useDropzone } from 'react-dropzone';
import Tesseract from 'tesseract.js';
import api from './api';

function Home() {
    const navigate = useNavigate();

    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [extractedText, setExtractedText] = useState('');
    const [analyzing, setAnalyzing] = useState(false);
    const [analyzingAll, setAnalyzingAll] = useState(false);
    const [analysisResults, setAnalysisResults] = useState(null);
    const [analysisError, setAnalysisError] = useState(null);
    const [personName, setPersonName] = useState('');
    const [quickAnalysis, setQuickAnalysis] = useState(null);
    const analysisRef = useRef(null);
    const [showCompletionNotice, setShowCompletionNotice] = useState(false);

    useEffect(() => {
        // Track page view
        trackEvent('page_viewed', { pageName: 'Home' });
    }, [navigate]);

    const saveStateToLocalStorage = (text, name, quickAnalysis) => {
        localStorage.setItem('savedText', text);
        localStorage.setItem('savedName', name);
        localStorage.setItem('savedQuickAnalysis', JSON.stringify(quickAnalysis));
    };
    
    const loadStateFromLocalStorage = () => {
        const savedText = localStorage.getItem('savedText');
        const savedName = localStorage.getItem('savedName');
        const savedQuickAnalysis = JSON.parse(localStorage.getItem('savedQuickAnalysis'));
        return { savedText, savedName, savedQuickAnalysis };
    };

    // Call checkPaymentStatus when component mounts
    useEffect(() => {
        checkPaymentStatus();
    }, []);

    useEffect(() => {
        // Check if URL has #analysis-results
        if (window.location.hash === '#analysis-results') {
            // Add a small delay to ensure content is rendered
            setTimeout(() => {
                document.getElementById('analysis-results')?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            }, 100);
        }
    }, []);

    useEffect(() => {
        const preventSafariSubmit = (e) => {
            if (e.target.tagName === 'BUTTON' || e.target.tagName === 'FORM') {
                e.preventDefault();
                e.stopPropagation();
            }
        };
        
        window.addEventListener('click', preventSafariSubmit, true);
        window.addEventListener('submit', preventSafariSubmit, true);
        
        return () => {
            window.removeEventListener('click', preventSafariSubmit, true);
            window.removeEventListener('submit', preventSafariSubmit, true);
        };
    }, []);

    const getCleanTextLength = (text) => {
        if (!text) return 0;
        const cleanedText = text.replace(/\[.*?\]/g, '');
        return cleanedText.length;
    };

    const [showJumbo, setShowJumbo] = useState(true);
    const [jumboOpacity, setJumboOpacity] = useState(1);

    useEffect(() => {
        // Arrêter l'animation après 10 secondes
        const timer = setTimeout(() => {
            setShowJumbo(false);
        }, 10000); // 10000ms = 10 secondes

        return () => clearTimeout(timer);
        
    }, []);

    useEffect(() => {
        // Créer un intervalle qui diminue l'opacité toutes les 2 secondes
        const fadeInterval = setInterval(() => {
            setJumboOpacity(prevOpacity => {
                if (prevOpacity <= 0) {
                    clearInterval(fadeInterval); // Arrêter l'intervalle quand l'opacité atteint 0
                    return 0;
                }
                return prevOpacity - 0.2; // Diminuer l'opacité de 0.1
            });
        }, 1000); // Toutes les secondes

        // Nettoyer l'intervalle après 5 étapes (10 secondes total pour le fade)
        setTimeout(() => clearInterval(fadeInterval), 10000);

        return () => clearInterval(fadeInterval);
        
    }, []);

    const [audio] = useState(() => {
        const audioElement = new Audio('/Girl from Petaluma-2.mp3');
        audioElement.volume = 0.5; // Set volume to 50%
        return audioElement;
    });

    useEffect(() => {
        if (analyzing) {
            audio.play().catch(error => {
                console.error('Audio playback failed:', error);
            });
        } else {
            audio.pause();
            audio.currentTime = 0;
        }

        // Cleanup function
        return () => {
            audio.pause();
            audio.currentTime = 0;
        };
    }, [analyzing, audio]);

    // React-Dropzone for drag-and-drop functionality
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                handleFileChange(acceptedFiles[0]); // Handle only the first file for simplicity
            }
        },
        accept:{
            'application/pdf': [], // Allow PDF files
            'image/*': [] // Allow all image formats
        },
        multiple: false, // Allow only one file to be uploaded at a time
    });

    // Handle file selection via file input or drag-and-drop
    const handleFileChange = (file) => {
        if (file.type === 'application/pdf' || file.type.startsWith('image/')) {
            trackEvent('file_selected', {
                fileType: file.type,
                fileName: file.name,
                fileSize: file.size
            });

            setFile(file);
            setMessage(`${file.name} is ready to upload.`);
            if (file.type === 'image/*') {
                performOCR(file); // Process image files with OCR
            }
        } else {
            trackEvent('file_rejected', {
                fileType: file.type,
                fileName: file.name,
                reason: 'unsupported_format'
            });
            setMessage('Only PDFs and image files are supported.');
        }
    };   
    
    // Perform OCR on the uploaded files
    const performOCR = async (file) => {
        const startTime = Date.now();
        setMessage('Performing OCR...');
        trackEvent('ocr_started', {
            fileType: file.type,
            fileSize: file.size,
        });

        try {
            const { data: { text } } = await Tesseract.recognize(file, 'eng');
            trackEvent('ocr_completed', {
                textLength: text.length,
                processingTime: Date.now() - startTime,
            });

            setExtractedText(text);
            setMessage('OCR completed successfully!');
        } catch (error) {
            trackEvent('ocr_error', {
                error: error.message,
                fileType: file.type,
            });
            console.error('Error during OCR:', error);
            setMessage('Error extracting text from image');
        }
    };

    // handleUpload function
    const handleUpload = useCallback(async () => {
        console.log("handleUpload started");

        // if (!file && !extractedText) {
        //     trackEvent('analysis_validation_error', {
        //         error: 'missing_content',
        //     });
        //     setMessage('Please upload a file or paste text before analyzing.');
        //     return;
        // }

        if (!extractedText?.trim()) {
            setMessage('Please paste text before analyzing.');
            return;
        }

        if (!personName?.trim()) {
            setMessage('Please enter the name of the person to analyze.');
            return;
        }

        // Clean the text by removing content between brackets
        const cleanedText = extractedText.replace(/\[.*?\]/g, '');

        console.log("Sending analysis request with:", {
            textLength: cleanedText.length,
            personName: personName
        });

        setAnalyzingAll(true);
        setShowCompletionNotice(false);
        setAnalysisError(null);
        // setMessage('Analyzing your text...');

        try {
            console.log("Starting analysis");
            // Track analysis start
            trackEvent('analysis_started', {
                contentType: file ? 'file' : 'text',
                textLength: cleanedText?.length || 0
            });


            // If there's a file, process it first
            // if (file) {
            //     console.log("Processing file");
            //     const formData = new FormData();
            //     formData.append('file', file);
            //     const uploadResponse = await api.saveFile(formData);
            //     textToAnalyze = uploadResponse.data.extractedText || '';
            //     setExtractedText(textToAnalyze);
            // }

            // Analyze the text
        
            const analysisResponse = await api.analyzeText({
                text: cleanedText.trim(),
                personName: personName.trim()
            });
    
            console.log("Analysis response received:", {
                success: analysisResponse.data.success,
                summaryExists: !!analysisResponse.data.summary,
                detailedResultsLength: analysisResponse.data.detailed_results?.length
            });
    
            setAnalysisResults(analysisResponse.data);
            setShowCompletionNotice(true);

            // Scroll to the analysis results
            analysisRef.current?.scrollIntoView({ 
                behavior: 'smooth',
                block: 'start'
            });
            
            trackEvent('analysis_completed', {
                textLength: cleanedText.length,
            });

            // Hide completion notice after 5 seconds
            setTimeout(() => setShowCompletionNotice(false), 5000);

        } catch (error) {
            console.error("Analysis error:", error.response || error);
            trackEvent('analysis_error', {
                error: error.message,
                stage: file ? 'file_processing' : 'text_analysis'
            });

            setAnalysisError(
                error.response?.data?.message || 
                error.response?.data?.error || 
                'Error analyzing your text. Please try again.'
            );
            setMessage('Error during analysis');
        } finally {
            console.log("Analysis completed");
            setAnalyzingAll(false);
        }
    }, [file, extractedText, personName]);

    // render analysis results function
    const renderAnalysisResults = () => {
        if (!analysisResults?.summary?.final_recap) return null;
    
        const { final_recap } = analysisResults.summary;
    
        return (
            <div className="analysis-results" style={{ marginTop: '20px', padding: '20px', fontSize: '15px' }}>
                <h3>Bestie's Analysis 💅</h3>
                
                <div className="final-recap" style={{ 
                    padding: '20px',
                    backgroundColor: 'white',
                    borderRadius: '12px',
                    border: '2px solid #D2D6F2'
                }}>
                    {/* Communication Style */}
                    <div className="section">
                        <h4>💬 <span className="small-gradient-text">Communication Vibe Check</span></h4>
                        <p><strong>Rating:</strong> {final_recap.communication_style.rating}/10</p>
                        <p>{final_recap.communication_style.explanation}</p>
                        <p><strong>Mixed Signals:</strong> {final_recap.communication_style.mixed_signals}</p>
                    </div>

                    <div style={{borderTop: '1px solid #D2D6F2', marginBottom: '30px'}}/>
    
                    {/* Observed Patterns */}
                    <div className="section">
                        <h4>🚩 <span className="small-gradient-text">Pattern Check</span></h4>
                        {Object.entries(final_recap.observed_patterns).map(([pattern, status]) => (
                            <div key={pattern} style={{marginBottom: '10px'}}>
                                <p><strong>{pattern.split('_')
                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                    .join(' ')}:</strong> {status}</p>
                            </div>
                        ))}
                    </div>

                    <div style={{borderTop: '1px solid #D2D6F2', marginBottom: '30px'}}/>
    
                    {/* Relationship Dynamics */}
                    <div className="section">
                        <h4>💫 <span className="small-gradient-text">Relationship Energy</span></h4>
                        <p><strong>Situationship Signs:</strong> {final_recap.relationship_dynamics.situationship_signs}</p>
                        <p><strong>Open-Ending Behavior:</strong> {final_recap.relationship_dynamics.open_ending_behavior}</p>
                    </div>

                    <div style={{borderTop: '1px solid #D2D6F2', marginBottom: '30px'}}/>
    
                    {/* Personality Assessment */}
                    <div className="section">
                        <h4>🧠 <span className="small-gradient-text">Personality Tea</span></h4>
                        <p><strong>Manipulative Tendencies:</strong> {final_recap.personality_assessment.manipulative_tendencies}</p>
                        <p><strong>Narcissistic Traits:</strong> {final_recap.personality_assessment.narcissistic_traits}</p>
                        <p><strong>Anxious Behaviors:</strong> {final_recap.personality_assessment.anxious_behaviors}</p>
                        <p><strong>Avoidant Behaviors:</strong> {final_recap.personality_assessment.avoidant_behaviors}</p>
                    </div>

                    <div style={{borderTop: '1px solid #D2D6F2', marginBottom: '30px'}}/>
    
                    {/* Attachment Style */}
                    <div className="section">
                        <h4>💝 <span className="small-gradient-text">Attachment Style</span></h4>
                        <p><strong>Primary Style:</strong> {final_recap.attachment_style.primary_style}</p>
                        <p>{final_recap.attachment_style.explanation}</p>
                        <div>
                            <strong>Key Signs:</strong>
                            <ul style={{listStyleType: 'none'}}>
                                {final_recap.attachment_style.key_indicators.map((indicator, index) => (
                                    <li key={index}>- {indicator}</li>
                                ))}
                            </ul>
                        </div>
                        <p><strong>Impact on Relationships:</strong> {final_recap.attachment_style.relationship_impact}</p>
                    </div>

                    {/* Bestie's Final Thoughts */}
                    <div className="section" style={{
                        marginTop: '20px',
                        padding: '15px',
                        backgroundColor: '#D2D6F2',
                        borderRadius: '8px'
                    }}>
                        <h4>💅 <span className="small-gradient-text">Bestie's Final Thoughts</span></h4>
                        <p><strong>Health Score:</strong> {final_recap.bestie_conclusion.overall_health_score}/10</p>
                        <div>
                            <strong>Main Concerns 💔:</strong>
                            <ul style={{listStyleType: 'none'}}>
                                {final_recap.bestie_conclusion.main_concerns.map((concern, index) => (
                                    <li key={index}>- {concern}</li>
                                ))}
                            </ul>
                        </div>
                        <div>
                            <br/>
                            <strong>Green Flags 💚:</strong>
                            <ul style={{listStyleType: 'none'}}>
                                {final_recap.bestie_conclusion.positive_aspects.map((positive, index) => (
                                    <li key={index}>- {positive}</li>
                                ))}
                            </ul>
                        </div>
                        <p><strong>Bestie's Advice:</strong> {final_recap.bestie_conclusion.advice}</p>
                        <p style={{color: '#ff69b4'}}>With love, <strong>#merra</strong></p>
                    </div>
                </div>
            </div>
        );
    };

    const handleQuickAnalysis = async (file) => {
        try {
            if (!extractedText?.trim()) {
                setMessage('Please paste text before analyzing.');
                return;
            }
    
            if (!personName?.trim()) {
                setMessage('Please enter the name of the person to analyze.');
                return;
            }

            // Remove text between brackets
            const cleanedText = extractedText.replace(/\[.*?\]/g, '');
    
            console.log("Sending analysis request with:", {
                textLength: cleanedText.length,
                personName: personName
            });
    
            setAnalyzing(true);
            setAnalysisError(null);

            trackEvent('short_analysis_started', {
                contentType: file ? 'file' : 'text',
                textLength: cleanedText?.length || 0
            });
    
            const response = await api.analyzeShortText({
                text: cleanedText.trim(),
                personName: personName.trim()
            });

            console.log("Response data:", response);

            // Access the data property from the Axios response
            const result = response.data;

            if (!result.success) {
                throw new Error(result.message || 'Analysis failed bestie 💅');
            }

            setQuickAnalysis(result.analysis);
            trackEvent('short_analysis_completed', {
                textLength: cleanedText.length,
            });
            
        } catch (error) {
            trackEvent('short_analysis_error', {
                error: error.message,
                stage: file ? 'file_processing' : 'text_analysis'
            });
            setAnalysisError(error.message);
        } finally {
            setAnalyzing(false);
        }
    };

    const QuickAnalysisResult = ({ analysis }) => {
        if (!analysis) return null;

        const renderToxicTrait = (info, trait) => {
            if (!info.detected) {
                return (
                    <p style={{color: '#2ecc71', fontStyle: 'italic'}}>
                        No {trait.replace(/_/g, ' ').toLowerCase()} detected bestie, you're safe! 💅✨
                    </p>
                );
            }
            return (
                <>
                    <p>Evidence: "{info.evidence}"</p>
                    <p style={{color: '#ff69b4'}}>{info.sass}</p>
                </>
            );
        };
    
        return (
            <div className="quick-analysis" style={{
                marginTop: '20px',
                padding: '20px',
                backgroundColor: '#fff',
                borderRadius: '12px',
                border: '2px solid #ff69b4',
                color: '#212932',
                fontSize: '15px',
                textAlign: 'left'
            }}>
                <h3 style={{textAlign: 'center'}}>💅 I have tea ! ✨</h3>
                
                <div className="section" style={{marginBottom: '30px'}}>
                    <h4 className="small-gradient-text" style={{textAlign: 'left'}}>First Impression:</h4>
                    <p>{analysis.quick_analysis.first_impression}</p>
                </div>

                <div style={{borderTop: '1px solid #AEC3D0', marginBottom: '30px'}}/>
    
                <div className="section" style={{marginBottom: '30px'}}>
                    <h4>🚩 <span className="small-gradient-text">The Tea on Their Toxic Traits:</span></h4>
                    {Object.entries(analysis.toxic_traits).map(([trait, info]) => (
                        <div key={trait} style={{marginBottom: '15px'}}>
                            <p><strong>{trait.replace(/_/g, ' ').split(' ')
                                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                .join(' ')}:</strong></p>
                            {renderToxicTrait(info, trait)}
                        </div>
                    ))}
                </div>
    
                <div style={{borderTop: '1px solid #AEC3D0', marginBottom: '30px'}}/>
    
                <div className="section" style={{marginBottom: '30px'}}>
                    <h4>🧠 <span className="small-gradient-text">The Vibe Check:</span></h4>
                    <p><strong>Attachment Style:</strong> {analysis.personality_reading.attachment_style || "Bestie, I might need more tea to figure this out! 🤔"}</p>
                    <p><strong>Narcissistic Vibes (out of 10):</strong> {analysis.personality_reading.narcissistic_vibes || "Not enough info to rate the narcissistic vibes!"}</p>
                    {analysis.personality_reading.manipulation_tactics?.length > 0 ? (
                    <div>
                        <strong>Manipulation Tactics Spotted:</strong>
                        <ul style={{
                            listStylePosition: 'inside',
                            paddingLeft: '0',
                            marginTop: '5px'
                        }}>
                            {analysis.personality_reading.manipulation_tactics.map((tactic, index) => (
                                <li key={index}>{tactic}</li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    <p><strong>Manipulation Tactics:</strong> No manipulation tactics detected, we love that for you! 💖</p>
                )}
                </div>
    
                <div style={{borderTop: '1px solid #AEC3D0', marginBottom: '30px'}}/>
    
                <div className="section" style={{marginBottom: '30px'}}>
                    <h4>💅 <span className="small-gradient-text">Final Tea:</span></h4>
                    <p>{analysis.bestie_conclusion.final_tea}</p>
                    <p><strong>Slay or Nay:</strong> {analysis.bestie_conclusion.slay_or_nay}</p>
                    <p><strong>Bestie's Advice:</strong> {analysis.bestie_conclusion.advice}</p>
                    <p style={{color: '#ff69b4'}}>With love, <strong>#merra</strong></p>
                </div>
            </div>
        );
    };

    const handleFullAnalysisPayment = async () => {
        try {
            // setAnalyzing(true);

            // Save current state
            saveStateToLocalStorage(extractedText, personName, quickAnalysis);
            
            // Create checkout session
            const response = await api.createCheckoutSessionForAnalysis();
            
            if (!response.data?.url) {
                throw new Error('Failed to create checkout session');
            }
    
            // Redirect to Stripe Checkout
            window.location.href = response.data.url;
    
        } catch (error) {
            console.error('Payment error:', error);
            setAnalysisError('Payment setup failed. Please try again.');
            // setAnalyzing(false);
        }
    };

    const checkPaymentStatus = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const sessionId = urlParams.get('session_id');
        
        if (sessionId) {
            try {
                // Restore saved state
                const { savedText, savedName, savedQuickAnalysis } = loadStateFromLocalStorage();
                if (savedText) setExtractedText(savedText);
                if (savedName) setPersonName(savedName);
                if (savedQuickAnalysis) setQuickAnalysis(savedQuickAnalysis);

                // Wait for state to be updated
                await new Promise(resolve => setTimeout(resolve, 0));

                const response = await api.checkPaymentStatus({ session_id: sessionId });
                
                if (response.data?.status === 'paid') {
                    // Clear any existing error messages
                    setMessage('');
                    setAnalysisError('');
                    
                    // Only proceed if we have the required data
                    if (savedText?.trim() && savedName?.trim()) {
                        // Payment successful, start analysis
                        handleUpload();
                        
                        // Clear saved state after successful restoration
                        localStorage.removeItem('savedText');
                        localStorage.removeItem('savedName');
                        localStorage.removeItem('savedQuickAnalysis');
                    } else {
                        setAnalysisError('Payment not completed. Please try again.');
                    }
                }

            } catch (error) {
                console.error('Error checking payment status:', error);
                setAnalysisError('Payment verification failed. Please try again or contact support.');
            }
        }
    };

    // Add dependencies to useEffect
    useEffect(() => {
        checkPaymentStatus();
    }, [handleUpload]); // Add handleUpload as dependency
    
    return (
        <div style={{backgroundColor: "#000000"/*'#FBFBFB'*/}}>
            <div style={{ textAlign: 'center'}}>

                <div class="card card--456">
                    <div class="relative flex flex-col items-center justify-center transition-bg">
                        <div className="floating-images" style={{zIndex: 2}}>
                            <img src="/text-screenshot1.png" style={{height:'2vw', position:'absolute', top:'50px', left:'20vw', borderRadius:'20px'}} alt="screenshot ahahah"/>
                            <img src="/text-screenshot2.png" style={{height:'3vw', position:'absolute', top:'100px', right:'10vw', borderRadius:'20px'}} alt="screenshot nah of course"/>
                            <img src="/text-screenshot3.png" style={{height:'2vw', position:'absolute', top:'200px', left:'10vw', borderRadius:'20px'}} alt="screenshot i'll be there in 45 minutes"/>
                            <img src="/text-screenshot4.png" style={{height:'2vw', position:'absolute', top:'300px', right:'3vw', borderRadius:'20px'}} alt="screenshot u kidding"/>
                            <img src="/text-screenshot5.png" style={{height:'2vw', position:'absolute', top:'400px', left:'5vw',}} alt="screenshot see you there"/>
                            <img src="/text-screenshot6.png" style={{height:'3vw', position:'absolute', top:'500px', right:'5vw',}} alt="screenshot i'm leaving soon"/>
                            <img src="/text-screenshot7.png" style={{height:'2.5vw', position:'absolute', top:'600px', left:'10vw',}} alt="screenshot thinking about you"/>
                        </div>

                        {/* Title */}
                        <div class="absolute inset-0 overflow-hidden">
                            <div class={showJumbo ? "jumbo absolute -inset-[10px]" : "absolute -inset-[10px]"} style={{height:'100%', width:'100%', transition: 'all 4s ease-in-out', opacity: jumboOpacity, zIndex: 1}}>
                                <div style={{height:'300px', width:'100px'}}/>
                            </div>
                            <div style={{position:'absolute', top:'100px', left:'0px', right:'0px'}}>
                                <h1 className="gradient-text" style={{maxWidth: '500px', marginLeft:'auto', marginRight:'auto'}}>
                                    Merra.ai
                                    <br/>
                                    <h3>Screw mixed signals,
                                        <br/>read between texts
                                    </h3>
                                </h1>
                            </div>
                        </div>

                        {/* Content */}
                        <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                            <br />
                            <br />

                            <main style={{ textAlign: 'center', marginTop: '0px', padding: '20px', marginBottom:'50px', paddingRight:'100px', paddingLeft:'100px', /*backgroundColor: '#f9f9f9',*/ borderRadius: '8px', width:'50vw'}}>
                                <p className="subtitle">
                                    Upload your chat history 💬 and let AI help you spot red flags in your relationships. 🚩
                                    <br/>
                                    <br/>
                                    Identify love bombing, breadcrumbing, gaslighting, and understand attachment styles.
                                </p>
                                <p style={{color:'grey', fontSize:'14px', lineHeight:'25px'}}>
                                🔒 100% Private – Your chats stay safe and encrypted.
                                <br/>
                                👤 Completely Anonymous – No sign-up required.
                                <br/>
                                💫 Free – Unless you need the advanced report for 1$.
                                <br/>
                                🧠 Backed by Psychology – Built with real relationship science and AI analysis.
                                </p>
                                <br/>
                                <p style={{color: '#ff69b4'}}><strong>#weListenAndWeJudge #Merra</strong></p>
                                {/* <div className="cta-buttons">
                                    <button 
                                        className="secondary-button"
                                        onClick={() => window.location.href = '#features'}
                                    >
                                        Learn More
                                    </button>
                                </div> */}
                                <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                                    <div class="scroll" style={{marginTop:'30px', alignSelf:'center', justifySelf:'center'}}/>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>

                <div style={{backgroundColor: "#FFFFFF"}}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                    onSubmit={e => e.preventDefault()} 
                    onClick={e => e.preventDefault()}
                    >
                        <div style={{
                            backgroundColor: "white",
                            borderRadius: '10px',
                            border: '0px solid #dfe1e5',
                            padding: '20px',
                            alignSelf: 'center',
                            justifySelf: 'center',
                            marginTop: '50px',
                            marginLeft: '20px',
                            marginRight: '20px',
                            paddingLeft: '50px',
                            paddingRight: '50px',
                            boxShadow: '10px 15px 40px 1px rgba(0, 0, 0, 0.1)'
                        }}>
                            <h2 className="gradient-text" style={{marginTop: '10px', fontSize: '25px'}}>
                                Upload your chat history
                            </h2>
                            <p>Export your chat history from WhatsApp {' '}
                            <a 
                                href="#how-to" 
                                onClick={(e) => {
                                    e.preventDefault();
                                    document.getElementById('how-to')?.scrollIntoView({ 
                                        behavior: 'smooth',
                                        block: 'start'
                                    });
                                }}
                                style={{
                                    color: '#ff69b4',
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                    fontWeight: 'bold',
                                    fontSize: '12px'
                                }}
                            >
                                (see how)
                            </a>
                            </p>
                            <p style={{
                                fontSize: '12px',
                                color: '#666',
                                margin: '0',
                                marginLeft: '5px',
                                marginBottom: '10px'
                            }}>
                                💡 We don't save, store or collect anything, we don't even have cookies.
                            </p>

                            {/* Drag-and-Drop Area */}
                            {/* <div
                                {...getRootProps()}
                                style={{
                                    border: '2px dashed #007bff',
                                    borderRadius: '8px',
                                    padding: '20px',
                                    paddingBottom: '10px',
                                    paddingTop: '10px',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    alignSelf: 'center',
                                    justifySelf: 'center',
                                    backgroundColor: isDragActive ? '#f0f8ff' : 'white',
                                    boxSizing: 'border-box',
                                    marginBottom: '15px'
                                }}
                            >
                                <input {...getInputProps()} />
                                <p style={{fontSize: '14px', margin: 0}}>
                                    {isDragActive ? 'Drop the file here...' : 'Drag & drop a PDF or image file here, or click to select one'}
                                </p>
                            </div>
                            
                            {!file && (
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    margin: '15px 0'
                                }}>
                                    <p style={{fontSize: '14px', margin: 0}}>OR</p>
                                </div>
                            )} */}

                            <div style={{marginBottom: '20px'}}>
                                <textarea
                                    placeholder="Paste text here..."
                                    value={extractedText}
                                    onChange={(e) => setExtractedText(e.target.value)}
                                    style={{
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        height: '100px',
                                        borderRadius: '10px',
                                        fontSize: '13px',
                                        padding: '14px',
                                        border: '1px solid #dfe1e5',
                                        resize: 'vertical'
                                    }}
                                />
                                <p style={{ 
                                    background: 'linear-gradient(to right, #000 20%, #AB5898 50%, #5C4BD3 80%)',
                                    WebkitBackgroundClip: 'text',
                                    backgroundClip: 'text',
                                    color: 'transparent',
                                    display: 'inline-block',
                                    margin: '5px 0 0 0',
                                    fontSize: '12px'
                                }}>
                                    Total characters: {getCleanTextLength(extractedText)?.toLocaleString() || 0}
                                </p>
                            </div>

                            {/* Name Input */}
                            <div style={{marginBottom: '20px'}}>
                            <p style={{
                                fontSize: '13px',
                                margin: '0',
                                marginLeft: '5px',
                                marginBottom: '10px',
                                textAlign: 'left'
                                }}>
                                    <b>Who should we analyze?</b> (not your name, the other person):
                                </p>
                                <input
                                    type="text"
                                    placeholder="Enter the name"
                                    value={personName}
                                    onChange={(e) => setPersonName(e.target.value)}
                                    style={{
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        height: '40px',
                                        borderRadius: '10px',
                                        fontSize: '13px',
                                        padding: '10px 14px',
                                        border: '1px solid #dfe1e5',
                                        marginBottom: '10px'
                                    }}
                                />
                                <p style={{
                                    fontSize: '12px',
                                    color: '#666',
                                    margin: '0',
                                    marginLeft: '5px'
                                }}>
                                    💡 We only use it to analyze their messages.
                                </p>
                            </div>
                            
                            {/* Analysis Button */}
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                marginBottom: '20px'
                            }}>
                                <button
                                    type="button"
                                    // onClick={handleUpload}
                                    onClick={handleQuickAnalysis}
                                    disabled={analyzing}
                                    className='waitlist-button'
                                    style={{
                                        margin: '0',
                                        borderWidth: '0',
                                        position: 'relative',
                                        width: '100%',
                                        cursor: analyzing ? 'not-allowed' : 'pointer',
                                        opacity: analyzing ? 0.7 : 1
                                    }}
                                >
                                    <span>{analyzing ? <><span className="spinner-border spinner-border-sm" />{' '}Analyzing...</> : 'Start analyzing'}</span>
                                    <span className="waitlist-shimmer"></span>
                                </button>
                            </div>

                            {analyzing && (
                                <div>
                                    <div className="shimmer" style={{textAlign: 'center', marginBottom: '15px'}}>
                                        <img src="/dancing-happy-dance.gif" alt="loading" style={{width: '90px', height: '90px'}}/>
                                    </div>
                                    <br/>
                                </div>
                            )}
                            
                            {/* Status Messages */}
                            {message && (
                                <div style={{
                                    textAlign: 'center',
                                    color: '#dc3545',
                                    fontSize: '12px',
                                    marginBottom: '15px'
                                }}>
                                    {message}
                                </div>
                            )}

                            {/* Loading Indicator */}
                            {analyzing && (
                                <div className="shimmer" style={{textAlign: 'center'}}>
                                    <p style={{fontSize: '12px', margin: 0}}>Processing your text...</p>
                                </div>
                            )}

                            {/* Error Display */}
                            {analysisError && (
                                <div style={{ 
                                    color: '#dc3545',
                                    padding: '10px',
                                    margin: '15px 0',
                                    borderRadius: '4px',
                                    backgroundColor: '#fff5f5',
                                    textAlign: 'center'
                                }}>
                                    {analysisError}
                                </div>
                            )}

                            <div style={{marginTop: '20px'}}>
                            {quickAnalysis && (
                                <>
                                    <QuickAnalysisResult analysis={quickAnalysis} />
                                </>
                                )
                            }
                            <div style={{height: '10px'}} id="analysis-results"/>
                            {quickAnalysis && (
                                <>
                                    <div
                                        id="quick-analysis"
                                        style={{
                                            marginTop: '20px',
                                            padding: '15px',
                                            backgroundColor: analyzingAll ? '#f0fff4' : '#fff5f5', // Green tint when analyzing
                                            borderRadius: '8px',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {analyzingAll ? (
                                            <p style={{
                                                fontSize: '14px',
                                                color: '#2f855a', // Dark green text
                                                marginBottom: '15px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                gap: '8px'
                                            }}>
                                                <span style={{
                                                    color: '#38a169', // Green checkmark
                                                    fontSize: '18px'
                                                }}>✓</span>
                                                Payment received! Bestie is now analyzing your entire chat history... 💅✨
                                            </p>
                                        ) : (
                                            <p style={{
                                                fontSize: '14px',
                                                color: '#666',
                                                marginBottom: '15px'
                                            }}>
                                                💅 This is the quick tea, based on the last 2000 characters of the conversation. 
                                                Want the full analysis? Let's dig deeper!
                                            </p>
                                        )}
                                        
                                        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                                            <button
                                                onClick={handleFullAnalysisPayment}
                                                disabled={analyzingAll}
                                                className='waitlist-button'
                                                style={{
                                                    margin: '0 auto',
                                                    borderWidth: '0',
                                                    position: 'relative',
                                                    width: '200px',
                                                    cursor: analyzingAll ? 'not-allowed' : 'pointer',
                                                    opacity: analyzingAll ? 0.7 : 1
                                                }}
                                            >
                                                <span>{analyzingAll ? 'Analyzing...' : 'Get Full Analysis for 0.99$'}</span>
                                                <span className="waitlist-shimmer"></span>
                                            </button>
                                            {analyzingAll && (
                                                <div className="shimmer" style={{textAlign: 'center', marginBottom: '15px', marginTop: '10px'}}>
                                                    <p style={{fontSize: '12px', margin: 0}}>Processing your text...</p>
                                                </div>
                                            )}

                                            {/* Error Display */}
                                            {analysisError && (
                                                <div style={{ 
                                                    color: '#dc3545',
                                                    padding: '10px',
                                                    margin: '15px 0',
                                                    borderRadius: '4px',
                                                    backgroundColor: '#fff5f5',
                                                    textAlign: 'center'
                                                }}>
                                                    <br/>
                                                    {analysisError}
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </>
                            )}
                            </div>

                             {/* Add some CSS for animation */}
                            <style>
                                {`
                                    @keyframes slideIn {
                                        from {
                                            transform: translateX(100%);
                                            opacity: 0;
                                        }
                                        to {
                                            transform: translateX(0);
                                            opacity: 1;
                                        }
                                    }
                                `}
                            </style>
        
                            {/* Analysis Results */}
                            <div style={{marginTop: '20px'}} ref={analysisRef}>
                                {renderAnalysisResults()}
                            </div>
                        </div>
                    </div>

                    {/* Features */}
                    <main>
                        <section id="features" style={{paddingRight:'50px', paddingLeft:'50px'}}>
                            <h2 className="gradient-text" style={{paddingTop:'100px', fontSize:'25px', width:'200px', marginLeft:'auto', marginRight:'auto'}}>Learn More</h2>
                            <p>We detect toxic behaviors in your relationships.</p>
                            <div className="features-grid">
                                <div>
                                    <p><b>💣 Love Bombing 💥 :</b> Excessive affection used to manipulate</p>
                                </div>
                                <div>
                                    <p><b>🍞 Breadcrumbing 🤏 :</b> Minimal effort to keep someone interested</p>
                                </div>
                                <div>
                                    <p><b>💨 Gaslighting 🤥 :</b> Manipulation that makes you question reality</p>
                                </div>
                                <div>
                                    <p><b>❤️‍🩹 Attachment Styles 😰 :</b> Anxious, avoidant, or secure patterns</p>
                                </div>
                                <div>
                                    <p><b>👑 Narcissistic Tendencies 🪞 :</b> Self-centered patterns and emotional manipulation</p>
                                </div>
                            </div>
                            <p /*style={{color:'grey'}}*/>And more...</p>
                        </section>
                    </main>

                    {/* How to */}
                    <main>
                        <section id="how-to" style={{marginTop:'100px', paddingRight:'50px', paddingLeft:'50px'}}>
                            <h2 className="gradient-text" style={{fontSize:'25px', width:'100px', marginLeft:'auto', marginRight:'auto'}}>How to</h2>
                            <p>export your chat history from your WhatsApp</p>
                            <img
                                src={`/toxic_ex_screenshot.png`}
                                style={{height:'50vh', borderRadius:'20px', boxShadow:'0px 5px 10px 5px rgba(0, 0, 0, 0.2)'}}
                                alt="WhatsApp Export"
                            />
                            <p style={{color:'grey', fontSize:'12px'}}>(And you see 👆, there's also an option to block them 👀 🙄 🙂)</p>
                        </section>
                    </main>

                                                        
                    <section>
                        {/* <ul class="code" style={{marginTop:'100px'}}>
                            <li tabindex="0" class="digit">
                            <span>A</span>
                            </li>
                            <li tabindex="0" class="digit">
                            <span>R</span>
                            </li>
                            <li tabindex="0" class="digit">
                            <span>E</span>
                            </li>
                            <li tabindex="0" class="digit">
                            <span> </span>
                            </li>
                            <li tabindex="0" class="digit">
                            <span>T</span>
                            </li>
                            <li tabindex="0" class="digit">
                            <span>H</span>
                            </li>
                            <li tabindex="0" class="digit">
                            <span>E</span>
                            </li>
                            <li tabindex="0" class="digit">
                            <span>Y</span>
                            </li>
                            <li tabindex="0" class="digit">
                            <span> </span>
                            </li>
                            <li tabindex="0" class="digit">
                            <span>B</span>
                            </li>
                            <li tabindex="0" class="digit">
                            <span>U</span>
                            </li>
                            <li tabindex="0" class="digit">
                            <span>L</span>
                            </li>
                            <li tabindex="0" class="digit">
                            <span>L</span>
                            </li>
                            <li tabindex="0" class="digit">
                            <span>S</span>
                            </li>
                            <li tabindex="0" class="digit">
                            <span>H</span>
                            </li>
                            <li tabindex="0" class="digit">
                            <span>I</span>
                            </li>
                            <li tabindex="0" class="digit">
                            <span>T</span>
                            </li>
                            <li tabindex="0" class="digit">
                            <span>T</span>
                            </li>
                            <li tabindex="0" class="digit">
                            <span>I</span>
                            </li>
                            <li tabindex="0" class="digit">
                            <span>N</span>
                            </li>
                            <li tabindex="0" class="digit">
                            <span>G</span>
                            </li>
                            <li tabindex="0" class="digit">
                            <span> </span>
                            </li>
                            <li tabindex="0" class="digit">
                            <span>Y</span>
                            </li>
                            <li tabindex="0" class="digit">
                            <span>O</span>
                            </li>
                            <li tabindex="0" class="digit">
                            <span>U</span>
                            </li>
                            <li tabindex="0" class="digit">
                            <span>?</span>
                            </li>
                        </ul> */}
                    </section>
                                
                    <footer className="home-footer" style={{marginTop:'100px', paddingBottom:'100px'}}>
                        <a href="/confidentiality-rules" style={{color:'#AB5898'}}>Privacy Policy</a>
                    </footer>
                </div>
            </div>
        </div>
    );
}

export default Home;