import './App.css';
import './DarkMode.css';

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from './LandingPage';
import FileUpload from './FileUpload';
import FilesPage from './FilesPage';
import FileUploadDark from './FileUploadDark';
import AdminDashboard from './components/AdminDashboard';
import ConfidentialityRules from './ConfidentialityRules';
import { isTokenExpired } from './utils/jwt';
import { initAmplitude } from './utils/amplitude';
import Dashboard from './Dashboard';
import Password from './password';
import Counter from './CounterPage';
import Home from './Home';
import Workspace from './Workspace';
import SidebarLayout from './SidebarLayout';
import ContentCalendar from './ContentCalendar';
import Settings from './Settings';
import { WorkspaceProvider } from './WorkspaceContext';
import { DarkModeProvider } from './DarkModeContext';
import { CreditProvider } from './CreditContext';
import { VideoGenerationProvider } from './VideoGenerationContext';

// Initialize Amplitude when your app starts
initAmplitude();

function App() {
    return (
        <DarkModeProvider>
          <CreditProvider>
            <VideoGenerationProvider>
              <Router>
                  <WorkspaceProvider>
                      <Routes>
                          <Route path="/" element={<LandingPage />} />
                          <Route path="/workspace" element={
                              <SidebarLayout>
                              <Workspace />
                              </SidebarLayout>
                          } />
                          <Route path="/content-calendar" element={
                              <SidebarLayout>
                              <ContentCalendar />
                              </SidebarLayout>
                          } />
                          <Route path="/settings" element={
                              <SidebarLayout>
                              <Settings />
                              </SidebarLayout>
                          } />
                          <Route path="/auth" element={<TokenCheck />} />
                          <Route path="/landing-page" element={<LandingPage />} />
                          <Route
                              path="/dashboard"
                              element={
                              <RequireAuth>
                                  <Dashboard />
                              </RequireAuth>
                              }
                          />
                          <Route
                              path="/upload"
                              element={
                              <RequireAuth>
                                  <FileUpload />
                              </RequireAuth>
                              }
                          />
                          <Route
                              path="/files"
                              element={
                              <RequireAuth>
                                  <FilesPage />
                              </RequireAuth>
                              }
                          />
                          <Route
                              path='/counter'
                              element={
                              <RequireAuth>
                                  <Counter />
                              </RequireAuth>
                              }
                          />
                          <Route
                              path="/password"
                              element={
                              <RequireAuth>
                                  <Password />
                              </RequireAuth>
                              }
                          />
                          <Route
                              path="/admin"
                              element={
                              <RequireAuth>
                                  <AdminDashboard />
                              </RequireAuth>
                              }
                          />
                          <Route
                              path="/dark"
                              element={
                              <RequireAuth>
                                  <FileUploadDark />
                              </RequireAuth>
                              }
                          />
                          <Route path="/confidentiality-rules" element={<ConfidentialityRules />} />
                          <Route path="*" element={<Navigate to="/" replace />} />
                      </Routes>
                  </WorkspaceProvider>
              </Router>
            </VideoGenerationProvider>
          </CreditProvider>
        </DarkModeProvider>
    );
}

// Component to check token and redirect accordingly
function TokenCheck() {
  const params = new URLSearchParams(window.location.search);
  const tokenFromUrl = params.get('token');

  React.useEffect(() => {
    if (tokenFromUrl) {
      localStorage.setItem('token', tokenFromUrl);
      window.history.replaceState({}, document.title, window.location.pathname);
      window.location.href = '/workspace';
      return;
    }

    const token = localStorage.getItem('token');
    if (token && !isTokenExpired(token)) {
      window.location.href = '/workspace';
    } else {
      window.location.href = '/landing-page';
    }
  }, [tokenFromUrl]);

  // Render this while the redirect happens
  return (
    <div>
      <div style={{ 
        position: 'fixed', 
        bottom: '20px', 
        left: '0', 
        width: '100%', 
        textAlign: 'center',
        padding: '10px'
      }}>
        <a href="/confidentiality-rules" style={{ color: '#666', textDecoration: 'none' }}>
          Privacy Policy
        </a>
      </div>
    </div>
  );
}

// Higher-order component for protected routes
function RequireAuth({ children }) {
  const token = localStorage.getItem('token');

  // Check for token existence and validity
  if (!token || isTokenExpired(token)) {
    return <Navigate to="/landing-page" />;
  }

  return children;
}

export default App;
