import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Calendar, Video, Settings, ChevronRight, ChevronLeft, Sun, Moon } from 'react-feather';
import './SidebarLayout.css';
import { useDarkMode } from './DarkModeContext';
import CreditDisplay from './CreditDisplay';
import api from './api';
import { useCredits } from './CreditContext';

const SidebarLayout = ({ children }) => {
    const [collapsed, setCollapsed] = useState(false);
    const location = useLocation();
    const { darkMode, toggleDarkMode } = useDarkMode();
    const [credits, setCredits] = useState({ total: 1600, used: 0, remaining: 1600 });
    const { addCredits } = useCredits();

    const toggleSidebar = () => {
        setCollapsed(!collapsed);
    };

    // Add this function to fetch credits
    const fetchCredits = async () => {
        try {
            const response = await api.getUserCredits();
            if (response.data) {
                setCredits({
                    total: response.data.total || 1600,
                    used: response.data.used || 0,
                    remaining: response.data.remaining || 1600
                });
            }
        } catch (error) {
            console.error('Error fetching credits:', error);
        }
    };

    // Fetch credits when component mounts
    useEffect(() => {
        fetchCredits();
    }, []);


    return (
        <div className={`layout-container ${darkMode ? 'dark-mode' : ''}`}>
            <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
                <div className="sidebar-toggle" onClick={toggleSidebar}>
                {collapsed ? <ChevronRight size={20} /> : <ChevronLeft size={20} />}
                </div>
                
                <div className="sidebar-header">
                {!collapsed ? <h3>Merra.ai</h3> : <h3>M.</h3>}
                </div>
                
                <nav className="sidebar-nav">
                <Link 
                    to="/workspace" 
                    className={`nav-item ${location.pathname === '/workspace' ? 'active' : ''}`}
                    title="Video Ad Generator"
                >
                    <div className="nav-icon">
                    <Video size={20} />
                    </div>
                    {!collapsed && <span>Video Ad Generator</span>}
                    {!collapsed && <div className="coming-soon-badge" style={{backgroundColor: '#FFD700', color: '#000'}}>beta</div>}
                </Link>
                
                <Link 
                    to="/content-calendar" 
                    className={`nav-item ${location.pathname === '/content-calendar' ? 'active' : ''}`}
                    title="Content Calendar"
                >
                    <div className="nav-icon">
                    <Calendar size={20} />
                    </div>
                    {!collapsed && <span>AI Content Gen</span>}
                    {!collapsed && <div className="coming-soon-badge">Coming Soon</div>}
                </Link>
                
                <Link 
                    to="/settings" 
                    className={`nav-item ${location.pathname === '/settings' ? 'active' : ''}`}
                    title="Settings"
                >
                    <div className="nav-icon">
                    <Settings size={20} />
                    </div>
                    {!collapsed && <span>Settings</span>}
                </Link>
                </nav>
                
                <div className="sidebar-footer">

                    <button 
                        className="add-credits-button" 
                        onClick={collapsed ? toggleSidebar : addCredits}
                        style={{textWrap: 'nowrap'}}
                    >
                        {collapsed ? '+' : 'Add 500 Credits'}
                    </button>
                    
                    <CreditDisplay collapsed={collapsed} credits={credits}/>

                    <button 
                        className="dark-mode-toggle" 
                        onClick={toggleDarkMode}
                        title={darkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
                    >
                        <div className="nav-icon">
                        {darkMode ? <Sun size={20} /> : <Moon size={20} />}
                        </div>
                        {!collapsed && <span>{darkMode ? "Light Mode" : "Dark Mode"}</span>}
                    </button>
                </div>
            </div>
            
            <div className={`main-content ${collapsed ? 'expanded' : ''}`}>
                {children}
            </div>
        </div>
        );
    };

export default SidebarLayout;