// This file contains the category data structure

const categories = [
    // {
    //     name: "Business and Industry",
    //     children: [
            // {
            //     name: "Advertising (Marketing)",
            //     size: "465,943,911 - 547,950,040"
            // },
            // {
            //     name: "Agriculture (Industry)",
            //     size: "389,563,392 - 458,126,550"
            // },
            // {
            //     name: "Architecture (Architecture)",
            //     size: "433,894,345 - 510,259,750"
            // },
            // {
            //     name: "Aviation (Air Travel)",
            //     size: "164,384,736 - 193,316,450"
            // },
            // {
            //     name: "Banking (Finance)",
            //     children: [
            //         {
            //             name: "Investment Banking (Banking)",
            //             size: "32,717,426 - 38,475,693"
            //         },
                    // {
                    //     name: "Online Banking (Banking)",
                    //     size: "90,556,292 - 106,494,200"
                    // },
                    // {
                    //     name: "Retail Banking (Banking)",
                    //     size: "23,764,328 - 27,946,850"
                    // }
            //     ]
            // },
            // {
            //     name: "Business (Business & Finance)",
            //     size: "997,456,139 - 1,173,008,420"
            // },
            // {
            //     name: "Construction (Industry)",
            //     size: "466,283,290 - 548,349,150"
            // },
            // {
            //     name: "Design (Design)",
            //     children: [
            //         {
            //             name: "Fashion Design (Design)",
            //             size: "347,376,821 - 408,515,142"
            //         },
            //         {
            //             name: "Graphic Design (Visual Art)",
            //             size: "315,060,008 - 370,510,570"
            //         },
            //         {
            //             name: "Interior Design (Design)",
            //             size: "488,461,241 - 574,430,420"
            //         }
            //     ]
            // },
            // {
            //     name: "Economics (Economics)",
            //     size: "308,751,105 - 363,091,300"
            // },
            // {
            //     name: "Engineering (Science)",
            //     size: "469,516,258 - 552,151,120"
            // },
            // {
            //     name: "Entrepreneurship (Business & Finance)",
            //     size: "382,208,471 - 449,477,162"
            // },
            // {
            //     name: "Higher Education (Education)",
            //     size: "563,361,079 - 662,512,630"
            // },
            // {
            //     name: "Management (Business & Finance)",
            //     size: "332,392,721 - 390,893,840"
            // },
            // {
            //     name: "Marketing (Business & Finance)",
            //     size: "605,494,039 - 712,060,990"
            // },
            // {
            //     name: "Online (Computing)",
            //     children: [
            //         {
            //             name: "Digital Marketing (Marketing)",
            //             size: "149,444,939 - 175,747,249"
            //         },
            //         {
            //             name: "Email Marketing (Marketing)",
            //             size: "14,417,554 - 16,955,044"
            //         },
            //         {
            //             name: "Online Advertising (Marketing)",
            //             size: "156,407,143 - 183,934,801"
            //         },
            //         {
            //             name: "Search Engine Optimization (Software)",
            //             size: "36,242,695 - 42,621,410"
            //         },
            //         {
            //             name: "Social Media (Online Media)",
            //             size: "633,785,255 - 745,331,460"
            //         },
            //         {
            //             name: "Social Media Marketing (Marketing)",
            //             size: "84,384,732 - 99,236,446"
            //         },
            //         {
            //             name: "Web Design (Websites)",
            //             size: "53,903,690 - 63,390,740"
            //         },
            //         {
            //             name: "Web Development (Websites)",
            //             size: "45,666,865 - 53,704,234"
            //         },
                    // {
                    //     name: "Web Hosting (Computing)",
                    //     size: "24,069,693 - 28,305,960"
                    // }
            //     ]
            // },
            // {
            //     name: "Personal Finance (Banking)",
            //     children: [
                    // {
                    //     name: "Credit Cards (Credit & Lending)",
                    //     size: "434,819,319 - 511,347,520"
                    // },
                    // {
                    //     name: "Insurance (Business & Finance)",
                    //     size: "340,092,619 - 399,948,920"
                    // },
                    // {
                    //     name: "Investment (Business & Finance)",
                    //     size: "412,508,707 - 485,110,240"
                    // },
                    // {
                    //     name: "Mortgage Loans (Banking)",
                    //     size: "144,190,748 - 169,568,320"
                    // }
            //     ]
            // },
            // {
            //     name: "Real Estate (Industry)",
            //     size: "424,648,647 - 499,386,810"
            // },
            // {
            //     name: "Retail (Industry)",
            //     size: "713,008,299 - 838,497,760"
            // },
            // {
            //     name: "Sales (Business & Finance)",
            //     size: "892,383,903 - 1,049,443,470"
            // },
            // {
            //     name: "Science (Science)",
            //     size: "600,334,489 - 705,993,360"
            // },
            // {
            //     name: "Small Business (Business & Finance)",
            //     size: "177,101,195 - 208,271,006"
            // }
    //     ]
    // },
    {
        name: "Entertainment (Leisure)",
        children: [
            {
                name: "Games (Leisure)",
                children: [
                    // {
                    //     name: "Action Games (Video Games)",
                    //     size: "152,967,687 - 179,890,000"
                    // },
                    // {
                    //     name: "Board Games (Games)",
                    //     size: "87,881,277 - 103,348,382"
                    // },
                    // {
                    //     name: "Browser Games (Video Games)",
                    //     size: "51,808,472 - 60,926,764"
                    // },
                    // {
                    //     name: "Card Games (Games)",
                    //     size: "272,395,153 - 320,336,700"
                    // },
                    // {
                    //     name: "Casino Games (Gambling)",
                    //     size: "43,101,736 - 50,687,642"
                    // },
                    {
                        name: "First-person Shooter Games (Video Games)",
                        size: "558,766,989 - 657,109,980"
                    },
                    // {
                    //     name: "Gambling (Gambling)",
                    //     size: "331,443,443 - 389,777,490"
                    // },
                    // {
                    //     name: "Massively Multiplayer Online Games (Video Games)",
                    //     size: "111,371,562 - 130,972,958"
                    // },
                    // {
                    //     name: "Massively Multiplayer Online Role-playing Games (Video Games)",
                    //     size: "109,827,267 - 129,156,867"
                    // },
                    {
                        name: "Online Games (Video Games)",
                        size: "595,532,287 - 700,345,970"
                    },
                    // {
                    //     name: "Online Poker (Gambling)",
                    //     size: "43,101,736 - 50,687,642"
                    // },
                    // {
                    //     name: "Puzzle Video Games (Video Games)",
                    //     size: "288,117,984 - 338,826,750"
                    // },
                    // {
                    //     name: "Racing Games (Video Game)",
                    //     size: "123,480,765 - 145,213,380"
                    // },
                    // {
                    //     name: "Role-playing Games (Video Games)",
                    //     size: "152,068,967 - 178,833,106"
                    // },
                    // {
                    //     name: "Simulation Games (Video Games)",
                    //     size: "92,506,020 - 108,787,080"
                    // },
                    // {
                    //     name: "Sports Games (Video Games)",
                    //     size: "167,944,091 - 197,502,252"
                    // },
                    // {
                    //     name: "Strategy Games (Games)",
                    //     size: "57,279,099 - 67,360,221"
                    // },
                    {
                        name: "Video Games (Gaming)",
                        size: "946,113,469 - 1,112,629,440"
                    },
                    // {
                    //     name: "Word Games (Games)",
                    //     size: "62,499,974 - 73,499,970"
                    // }
                ]
            },
            {
                name: "Live Events (Entertainment)",
                children: [
                    // {
                    //     name: "Ballet (Dance)",
                    //     size: "100,333,394 - 117,992,072"
                    // },
                    // {
                    //     name: "Bars (Bars, Clubs & Nightlife)",
                    //     size: "294,046,380 - 345,798,543"
                    // },
                    // {
                    //     name: "Concerts (Music Event)",
                    //     size: "259,957,134 - 305,709,590"
                    // },
                    // {
                    //     name: "Dancehalls (Music)",
                    //     size: "103,399,718 - 121,598,069"
                    // },
                    // {
                    //     name: "Music Festivals (Events)",
                    //     size: "293,265,824 - 344,880,610"
                    // },
                    // {
                    //     name: "Nightclubs (Bars, Clubs & Nightlife)",
                    //     size: "334,577,993 - 393,463,720"
                    // },
                    // {
                    //     name: "Parties (Event)",
                    //     size: "384,128,267 - 451,734,843"
                    // },
                    // {
                    //     name: "Plays (Performing Arts)",
                    //     size: "205,761,207 - 241,975,180"
                    // },
                    {
                        // name: "Theatre (Performing Arts)",
                        // size: "512,677,397 - 602,908,620"
                    }
                ]
            },
            {
                name: "Movies (Entertainment & Media)",
                children: [
                    {
                        name: "Action Movies (Movies)",
                        size: "627,385,552 - 737,805,410"
                    },
                    {
                        name: "Animated Movies (Movies)",
                        size: "418,699,651 - 492,390,790"
                    },
            //         {
            //             name: "Anime Movies (Movies)",
            //             size: "385,607,074 - 453,473,920"
            //         },
            //         {
            //             name: "Bollywood Movies (Movies)",
            //             size: "374,775,850 - 440,736,400"
            //         },
                    {
                        // name: "Comedy Movies (Movies)",
                        // size: "1,006,576,522 - 1,183,733,990"
                    },
            //         {
            //             name: "Documentary Movies (Movies)",
            //             size: "440,272,559 - 517,760,530"
            //         },
            //         {
            //             name: "Drama Movies (Movies)",
            //             size: "473,430,323 - 556,754,060"
            //         },
            //         {
            //             name: "Horror Movies (Movies)",
            //             size: "376,549,897 - 442,822,680"
            //         },
            //         {
            //             name: "Musical Theatre (Performing Arts)",
            //             size: "99,099,132 - 116,540,580"
            //         },
            //         {
            //             name: "Science Fiction Movies (Movies)",
            //             size: "363,505,918 - 427,482,960"
            //         },
                    {
                        // name: "Thriller Movies (Movies)",
                        // size: "553,071,904 - 650,412,560"
                    }
                ]
            },
            {
                name: "Music (Entertainment & Media)",
                children: [
            //         {
            //             name: "Blues Music (Music)",
            //             size: "453,873,086 - 533,754,750"
            //         },
            //         {
            //             name: "Classical Music (Music)",
            //             size: "324,272,448 - 381,344,400"
            //         },
            //         {
            //             name: "Country Music (Music)",
            //             size: "470,572,865 - 553,393,690"
            //         },
            //         {
            //             name: "Dance Music (Music)",
            //             size: "285,610,654 - 335,878,130"
            //         },
                    {
                        // name: "Electronic Music (Music)",
                        // size: "763,266,700 - 897,601,640"
                    },
                    {
                        // name: "Heavy Metal Music (Music)",
                        // size: "603,639,736 - 709,880,330"
                    },
                    {
                        // name: "Hip Hop Music (Music)",
                        // size: "824,386,198 - 969,478,170"
                    },
            //         {
            //             name: "Jazz Music (Music)",
            //             size: "462,368,469 - 543,745,320"
            //         },
            //         {
            //             name: "Music Videos (Entertainment & Media)",
            //             size: "985,474,268 - 1,158,917,740"
            //         },
                    {
                        // name: "Pop Music (Music)",
                        // size: "994,586,870 - 1,169,634,160"
                    },
                    {
                        // name: "Rhythm and Blues Music (Music)",
                        // size: "669,180,255 - 786,955,980"
                    },
                    {
                        // name: "Rock Music (Music)",
                        // size: "959,474,906 - 1,128,342,490"
                    },
            //         {
            //             name: "Soul Music (Music)",
            //             size: "461,193,307 - 542,363,330"
            //         }
                ]
            },
            // {
            //     name: "Reading (Communication)",
            //     children: [
            //         {
            //             name: "Books (Publications)",
            //             size: "581,479,795 - 683,820,240"
            //         },
            //         {
            //             name: "Comics (Comics & Cartoons)",
            //             size: "284,793,789 - 334,917,497"
            //         },
            //         {
            //             name: "E-books (Publications)",
            //             size: "366,680,399 - 431,216,150"
            //         },
            //         {
            //             name: "Fiction Books (Publications)",
            //             size: "407,642,219 - 479,387,250"
            //         },
            //         {
            //             name: "Literature (Publications)",
            //             size: "372,953,690 - 438,593,540"
            //         },
            //         {
            //             name: "Magazines (Publications)",
            //             size: "613,485,722 - 721,459,210"
            //         },
            //         {
            //             name: "Manga (Anime & Manga)",
            //             size: "226,028,885 - 265,809,969"
            //         },
            //         {
            //             name: "Mystery Fiction (Entertainment & Media)",
            //             size: "147,119,005 - 173,011,950"
            //         },
            //         {
            //             name: "Newspapers (Publications)",
            //             size: "815,218,979 - 958,697,520"
            //         },
            //         {
            //             name: "Non-fiction Books (Publications)",
            //             size: "36,691,106 - 43,148,741"
            //         },
            //         {
            //             name: "Romance Novels (Publications)",
            //             size: "229,731,352 - 270,164,070"
            //         }
            //     ]
            // },
            {
                name: "TV (Movies & Television)",
                children: [
            //         {
            //             name: "TV Game Shows (Television Show)",
            //             size: "110,719,600 - 130,206,250"
            //         },
                    {
                        // name: "TV Reality Shows (Movies & Television)",
                        // size: "520,518,945 - 612,130,280"
                    },
            //         {
            //             name: "TV Talkshows (Television Show)",
            //             size: "131,425,399 - 154,556,270"
            //         }
                ]
            }
        ]
    },
    {
        name: "Family and Relationships",
        children: [
            {
                // name: "Family (Social Concept)",
                // size: "1,029,906,930 - 1,211,170,550"
            },
    //         {
    //             name: "Fatherhood (Children & Parenting)",
    //             size: "352,672,278 - 414,742,600"
    //         },
    //         {
    //             name: "Friendship (Relationships)",
    //             size: "716,429,413 - 842,520,990"
    //         },
    //         {
    //             name: "Marriage (Weddings)",
    //             size: "233,638,750 - 274,759,170"
    //         },
            {
                // name: "Motherhood (Children & Parenting)",
                // size: "698,407,040 - 821,326,680"
            },
    //         {
    //             name: "Parenting (Children & Parenting)",
    //             size: "284,294,627 - 334,330,482"
    //         },
    //         {
    //             name: "Weddings (Weddings)",
    //             size: "304,885,943 - 358,545,870"
    //         }
        ]
    },
    {
        name: "Fitness and Wellness (Fitness)",
        children: [
    //         {
    //             name: "Bodybuilding (Sport)",
    //             size: "196,138,179 - 230,658,499"
    //         },
            {
                name: "Physical Exercise (Fitness)",
                size: "646,978,494 - 760,846,710"
            },
            {
                name: "Physical Fitness (Fitness)",
                size: "678,866,522 - 798,347,030"
            },
    //         {
    //             name: "Running (Sport)",
    //             size: "295,862,363 - 347,934,139"
    //         },
    //         {
    //             name: "Weight Training (Weightlifting)",
    //             size: "191,719,338 - 225,461,942"
    //         },
    //         {
    //             name: "Yoga (Fitness)",
    //             size: "382,445,073 - 449,755,406"
    //         }
        ]
    },
    // {
    //     name: "Food and Drink (Consumables)",
    //     children: [
    //         {
    //             name: "Alcoholic Beverages (Food & Drink)",
    //             children: [
    //                 {
    //                     name: "Beer (Alcoholic Drinks)",
    //                     size: "333,167,653 - 391,805,160"
    //                 },
    //                 {
    //                     name: "Distilled Beverage (Liquor)",
    //                     size: "204,502,551 - 240,495,000"
    //                 },
    //                 {
    //                     name: "Wine (Alcoholic Drinks)",
    //                     size: "332,466,045 - 390,980,070"
    //                 }
    //             ]
    //         },
            {
                name: "Beverages (Food & Drink)",
                children: [
                    {
                        // name: "Coffee (Food & Drink)",
                        // size: "535,770,493 - 630,066,100"
                    },
    //                 {
    //                     name: "Energy Drinks (Nonalcoholic Beverage)",
    //                     size: "97,544,710 - 114,712,580"
    //                 },
    //                 {
    //                     name: "Juice (Nonalcoholic Beverage)",
    //                     size: "223,882,925 - 263,286,320"
    //                 },
    //                 {
    //                     name: "Soft Drinks (Nonalcoholic Beverage)",
    //                     size: "187,387,091 - 220,367,220"
    //                 },
    //                 {
    //                     name: "Tea (Nonalcoholic Beverage)",
    //                     size: "395,174,719 - 464,725,470"
    //                 }
                ]
            },
    //         {
    //             name: "Cooking (Food & Drink)",
    //             children: [
    //                 {
    //                     name: "Baking (Cooking)",
    //                     size: "356,463,045 - 419,200,542"
    //                 },
    //                 {
    //                     name: "Recipes (Food & Drink)",
    //                     size: "480,500,399 - 565,068,470"
    //                 }
    //             ]
    //         },
    //         {
    //             name: "Cuisine (Food & Drink)",
    //             children: [
    //                 {
    //                     name: "Chinese Cuisine (Food & Drink)",
    //                     size: "152,438,027 - 179,267,120"
    //                 },
    //                 {
    //                     name: "French Cuisine (Food & Drink)",
    //                     size: "89,071,692 - 104,748,310"
    //                 },
    //                 {
    //                     name: "German Cuisine (Food & Drink)",
    //                     size: "29,112,772 - 34,236,620"
    //                 },
    //                 {
    //                     name: "Greek Cuisine (Food & Drink)",
    //                     size: "31,516,505 - 37,063,410"
    //                 },
    //                 {
    //                     name: "Indian Cuisine (Food & Drink)",
    //                     size: "91,538,323 - 107,649,069"
    //                 },
    //                 {
    //                     name: "Italian Cuisine (Food & Drink)",
    //                     size: "146,667,498 - 172,480,978"
    //                 },
    //                 {
    //                     name: "Japanese Cuisine (Food & Drink)",
    //                     size: "135,928,681 - 159,852,130"
    //                 },
    //                 {
    //                     name: "Korean Cuisine (Food & Drink)",
    //                     size: "102,158,375 - 120,138,250"
    //                 },
    //                 {
    //                     name: "Latin American Cuisine (Food & Drink)",
    //                     size: "41,796,581 - 49,152,780"
    //                 },
    //                 {
    //                     name: "Mexican Cuisine (Food & Drink)",
    //                     size: "102,614,047 - 120,674,120"
    //                 },
    //                 {
    //                     name: "Middle Eastern Cuisine (Food & Drink)",
    //                     size: "28,441,849 - 33,447,615"
    //                 },
    //                 {
    //                     name: "Spanish Cuisine (Food & Drink)",
    //                     size: "35,717,153 - 42,003,372"
    //                 },
    //                 {
    //                     name: "Thai Cuisine (Food & Drink)",
    //                     size: "57,123,875 - 67,177,678"
    //                 },
    //                 {
    //                     name: "Vietnamese Cuisine (Food & Drink)",
    //                     size: "48,440,493 - 56,966,020"
    //                 }
    //             ]
    //         },
    //         {
    //             name: "Food (Food & Drink)",
    //             children: [
    //                 {
    //                     name: "Barbecue (Cooking)",
    //                     size: "348,044,897 - 409,300,800"
    //                 },
    //                 {
    //                     name: "Chocolate (Food & Drink)",
    //                     size: "475,445,765 - 559,124,220"
    //                 },
    //                 {
    //                     name: "Desserts (Food & Drink)",
    //                     size: "398,663,415 - 468,828,177"
    //                 },
    //                 {
    //                     name: "Fast Food (Food & Drink)",
    //                     size: "445,818,967 - 524,283,106"
    //                 },
    //                 {
    //                     name: "Organic Food (Food & Drink)",
    //                     size: "273,132,221 - 321,203,492"
    //                 },
    //                 {
    //                     name: "Pizza (Food & Drink)",
    //                     size: "441,634,838 - 519,362,570"
    //                 },
    //                 {
    //                     name: "Seafood (Food & Drink)",
    //                     size: "270,206,906 - 317,763,322"
    //                 },
    //                 {
    //                     name: "Veganism (Diets)",
    //                     size: "324,563,265 - 381,686,400"
    //                 },
    //                 {
    //                     name: "Vegetarianism (Diets)",
    //                     size: "207,809,507 - 244,383,981"
    //                 }
    //             ]
    //         },
    //         {
    //             name: "Restaurants (Dining)",
    //             children: [
    //                 {
    //                     name: "Coffeehouses (Coffee)",
    //                     size: "411,936,607 - 484,437,450"
    //                 },
    //                 {
    //                     name: "Diners (Restaurant)",
    //                     size: "117,957,833 - 138,718,412"
    //                 },
    //                 {
    //                     name: "Fast Casual Restaurants (Restaurant)",
    //                     size: "127,329,706 - 149,739,735"
    //                 },
    //                 {
    //                     name: "Fast Food Restaurants (Dining)",
    //                     size: "169,866,686 - 199,763,223"
    //                 }
    //             ]
    //         }
    //     ]
    // },
    {
        name: "Hobbies and Activities",
        children: [
            // {
            //     name: "Arts and Music (Art)",
            //     children: [
            //         {
            //             name: "Acting (Performing Arts)",
            //             size: "195,934,863 - 230,419,400"
            //         },
            //         {
            //             name: "Crafts (Hobbies)",
            //             size: "423,784,727 - 498,370,840"
            //         },
                    {
                        // name: "Dance (Art)",
                        // size: "545,935,068 - 642,019,640"
                    },
            //         {
            //             name: "Drawing (Visual Art)",
            //             size: "198,107,763 - 232,974,730"
            //         },
            //         {
            //             name: "Drums (Instruments)",
            //             size: "116,439,379 - 136,932,710"
            //         },
            //         {
            //             name: "Fine Art (Visual Art)",
            //             size: "140,074,631 - 164,727,767"
            //         },
            //         {
            //             name: "Guitar (Instruments)",
            //             size: "150,172,134 - 176,602,430"
            //         },
            //         {
            //             name: "Painting (Visual Art)",
            //             size: "402,405,442 - 473,228,800"
            //         },
            //         {
            //             name: "Performing Arts (Performing Arts)",
            //             size: "431,488,095 - 507,430,000"
            //         },
                    {
                        // name: "Photography (Visual Art)",
                        // size: "1,117,351,836 - 1,314,005,760"
                    },
            //         {
            //             name: "Sculpture (Art)",
            //             size: "132,166,649 - 155,427,980"
            //         },
            //         {
            //             name: "Singing (Music)",
            //             size: "425,761,938 - 500,696,040"
            //         },
            //         {
            //             name: "Writing (Communication)",
            //             size: "341,526,028 - 401,634,610"
            //         }
            //     ]
            // },
            // {
            //     name: "Home and Garden",
            //     children: [
            //         {
            //             name: "Do it Yourself (DIY)",
            //             size: "418,387,661 - 492,023,890"
            //         },
            //         {
            //             name: "Furniture (Home Furnishings)",
            //             size: "522,148,358 - 614,046,470"
            //         },
            //         {
            //             name: "Gardening (Outdoor Activities)",
            //             size: "356,451,726 - 419,187,230"
            //         },
            //         {
            //             name: "Home Appliances (Consumer Electronics)",
            //             size: "265,301,547 - 311,994,620"
            //         },
            //         {
            //             name: "Home Improvement (Home & Garden)",
            //             size: "335,748,624 - 394,840,382"
            //         }
            //     ]
            // },
            {
                name: "Pets (Animals)",
                children: [
                    // {
                    //     name: "Birds (Animals)",
                    //     size: "353,079,693 - 415,221,720"
                    // },
                    {
                        name: "Cats (Animals)",
                        size: "448,420,365 - 527,342,350"
                    },
                    {
                        name: "Dogs (Animals)",
                        size: "491,744,251 - 578,291,240"
                    },
                    // {
                    //     name: "Fish (Animals)",
                    //     size: "321,510,238 - 378,096,040"
                    // },
                    // {
                    //     name: "Horses (Animals)",
                    //     size: "258,434,243 - 303,918,670"
                    // },
                    // {
                    //     name: "Pet Food (Pet Supplies)",
                    //     size: "108,416,370 - 127,497,652"
                    // },
                    // {
                    //     name: "Rabbits (Animals)",
                    //     size: "114,737,916 - 134,931,790"
                    // },
                    // {
                    //     name: "Reptiles (Animals)",
                    //     size: "49,090,909 - 57,730,910"
                    // }
                ]
            },
            // {
            //     name: "Politics and Social Issues (Politics)",
            //     children: [
            //         {
            //             name: "Charity and Causes (Social Causes)",
            //             size: "64,685,429 - 76,070,065"
            //         },
            //         {
            //             name: "Community Issues (Law & Government)",
            //             size: "236,059,226 - 277,605,650"
            //         },
            //         {
            //             name: "Law (Law & Legal Services)",
            //             size: "477,592,806 - 561,649,140"
            //         },
            //         {
            //             name: "Politics (Politics)",
            //             size: "460,704,668 - 541,788,690"
            //         },
            //         {
            //             name: "Volunteering (Social Causes)",
            //             size: "76,758,137 - 90,267,570"
            //         }
            //     ]
            // },
            {
                name: "Travel (Travel & Tourism)",
                children: [
            //         {
            //             name: "Adventure Travel (Travel & Tourism)",
            //             size: "275,203,844 - 323,639,721"
            //         },
            //         {
            //             name: "Air Travel (Transportation)",
            //             size: "305,795,457 - 359,615,458"
            //         },
            //         {
            //             name: "Beaches (Places)",
            //             size: "413,340,857 - 486,088,848"
            //         },
            //         {
            //             name: "Car Rentals (Transportation)",
            //             size: "153,514,217 - 180,532,720"
            //         },
            //         {
            //             name: "Cruises (Travel & Tourism Business)",
            //             size: "172,715,314 - 203,113,210"
            //         },
            //         {
            //             name: "Ecotourism (Travel & Tourism)",
            //             size: "124,376,713 - 146,267,015"
            //         },
                    {
                        // name: "Hotels (Lodging)",
                        // size: "603,371,853 - 709,565,300"
                    },
            //         {
            //             name: "Lakes (Body of Water)",
            //             size: "168,765,374 - 198,468,080"
            //         },
            //         {
            //             name: "Mountains (Places)",
            //             size: "309,836,930 - 364,368,230"
            //         },
                    {
                        name: "Nature (Science)",
                        size: "815,391,156 - 958,900,000"
                    },
            //         {
            //             name: "Theme Parks (Leisure)",
            //             size: "199,474,285 - 234,581,760"
            //         },
                    {
                        // name: "Tourism (Industry)",
                        // size: "773,164,277 - 909,241,190"
                    },
            //         {
            //             name: "Vacations (Social Concept)",
            //             size: "312,986,868 - 368,072,557"
            //         }
                ]
            },
            {
                name: "Vehicles (Transportation)",
                children: [
                    {
                        // name: "Automobiles (Vehicles)",
                        // size: "677,437,057 - 796,665,980"
                    },
            //         {
            //             name: "Boats (Watercraft)",
            //             size: "145,653,103 - 171,288,050"
            //         },
            //         {
            //             name: "Electric Vehicle (Vehicle)",
            //             size: "98,080,261 - 115,342,387"
            //         },
            //         {
            //             name: "Hybrids (Vehicle)",
            //             size: "63,554,330 - 74,739,893"
            //         },
            //         {
            //             name: "Minivans (Vehicle)",
            //             size: "48,699,184 - 57,270,241"
            //         },
                    {
                        name: "Motorcycles (Vehicles)",
                        size: "415,968,877 - 489,179,400"
                    },
            //         {
            //             name: "RVs (Vehicle)",
            //             size: "53,278,409 - 62,655,410"
            //         },
            //         {
            //             name: "SUVs (Vehicles)",
            //             size: "232,294,159 - 273,177,931"
            //         },
            //         {
            //             name: "Scooters (Vehicle)",
            //             size: "116,142,112 - 136,583,124"
            //         },
            //         {
            //             name: "Trucks (Vehicles)",
            //             size: "272,359,982 - 320,295,340"
            //         }
                ]
            }
        ]
    },
    {
        name: "Shopping and Fashion",
        children: [
            {
                name: "Beauty (Social Concept)",
                children: [
    //                 {
    //                     name: "Beauty Salons (Cosmetics)",
    //                     size: "618,195,365 - 726,997,750"
    //                 },
                    {
                        // name: "Cosmetics (Personal Care)",
                        // size: "954,268,571 - 1,122,219,840"
                    },
    //                 {
    //                     name: "Fragrances (Cosmetics)",
    //                     size: "557,077,516 - 655,123,159"
    //                 },
    //                 {
    //                     name: "Hair Products (Hair Care)",
    //                     size: "725,744,795 - 853,475,880"
    //                 },
    //                 {
    //                     name: "Spas (Personal Care)",
    //                     size: "588,815,610 - 692,447,158"
    //                 },
    //                 {
    //                     name: "Tattoos (Body Art)",
    //                     size: "495,843,528 - 583,111,990"
    //                 }
                ]
            },
    //         {
    //             name: "Clothing (Apparel)",
    //             children: [
    //                 {
    //                     name: "Children's Clothing (Apparel)",
    //                     size: "261,869,502 - 307,958,535"
    //                 },
    //                 {
    //                     name: "Men's Clothing (Apparel)",
    //                     size: "455,533,027 - 535,706,840"
    //                 },
    //                 {
    //                     name: "Shoes (Footwear)",
    //                     size: "841,455,391 - 989,551,540"
    //                 },
    //                 {
    //                     name: "Women's Clothing (Apparel)",
    //                     size: "598,187,925 - 703,469,000"
    //                 }
    //             ]
    //         },
    //         {
    //             name: "Fashion Accessories (Accessories)",
    //             children: [
    //                 {
    //                     name: "Dresses (Apparel)",
    //                     size: "573,380,000 - 674,294,881"
    //                 },
    //                 {
    //                     name: "Handbags (Accessories)",
    //                     size: "413,353,863 - 486,104,143"
    //                 },
    //                 {
    //                     name: "Jewelry (Apparel)",
    //                     size: "721,781,530 - 848,815,080"
    //                 },
    //                 {
    //                     name: "Sunglasses (Eyewear)",
    //                     size: "370,515,170 - 435,725,841"
    //                 }
    //             ]
    //         },
    //         {
    //             name: "Shopping (Retail)",
    //             children: [
    //                 {
    //                     name: "Boutiques (Retailers)",
    //                     size: "532,794,960 - 626,566,874"
    //                 },
    //                 {
    //                     name: "Coupons (Coupons & Discounts)",
    //                     size: "577,801,581 - 679,494,660"
    //                 },
    //                 {
    //                     name: "Discount Stores (Retail)",
    //                     size: "393,583,676 - 462,854,404"
    //                 },
    //                 {
    //                     name: "Luxury Goods (Retail)",
    //                     size: "673,653,435 - 792,216,440"
    //                 },
    //                 {
    //                     name: "Online Shopping (Retail)",
    //                     size: "1,347,410,025 - 1,584,554,190"
    //                 },
    //                 {
    //                     name: "Shopping Malls (Retail)",
    //                     size: "588,304,336 - 691,845,900"
    //                 }
    //             ]
    //         },
    //         {
    //             name: "Toys (Toys)",
    //             size: "480,501,513 - 565,069,780"
    //         }
        ]
    },
    {
        name: "Sports and Outdoors",
        children: [
            {
                name: "Outdoor Recreation (Outdoors Activities)",
                children: [
                    // {
                    //     name: "Boating (Outdoors Activities)",
                    //     size: "76,591,163 - 90,071,208"
                    // },
                    {
                        name: "Camping (Outdoors Activities)",
                        size: "240,108,356 - 282,367,427"
                    },
                    // {
                    //     name: "Fishing (Outdoors Activities)",
                    //     size: "278,638,503 - 327,678,880"
                    // },
                    // {
                    //     name: "Horseback Riding (Horse Sport)",
                    //     size: "102,889,171 - 120,997,666"
                    // },
                    // {
                    //     name: "Hunting (Sport)",
                    //     size: "201,917,244 - 237,454,680"
                    // },
                    // {
                    //     name: "Mountain Biking (Cycling)",
                    //     size: "94,696,265 - 111,362,808"
                    // },
                    {
                        name: "Surfing (Water Sport)",
                        size: "126,273,336 - 148,497,444"
                    }
                ]
            },
            {
                name: "Sports (Sports)",
                children: [
                    {
                        name: "American Football (Sport)",
                        size: "417,768,477 - 491,295,730"
                    },
                    {
                        name: "Association Football (Soccer)",
                        size: "1,239,072,202 - 1,457,148,910"
                    },
            //         {
            //             name: "Auto Racing (Motor Sports)",
            //             size: "316,189,499 - 371,838,851"
            //         },
                    {
                        name: "Baseball (Sport)",
                        size: "450,915,484 - 530,276,610"
                    },
            //         {
            //             name: "Basketball (Sport)",
            //             size: "714,404,481 - 840,139,670"
            //         },
            //         {
            //             name: "College Football (College Sports)",
            //             size: "106,642,491 - 125,411,570"
            //         },
            //         {
            //             name: "Golf (Sport)",
            //             size: "264,069,122 - 310,545,288"
            //         },
            //         {
            //             name: "Marathons (Running Event)",
            //             size: "189,882,514 - 223,301,837"
            //         },
                    {
                        name: "Skiing (Skiing & Snowboarding)",
                        size: "141,235,696 - 166,093,179"
                    },
                    {
                        name: "Snowboarding (Skiing & Snowboarding)",
                        size: "109,337,720 - 128,581,159"
                    },
            //         {
            //             name: "Swimming (Water Sport)",
            //             size: "228,292,881 - 268,472,429"
            //         },
                    {
                        name: "Tennis (Sport)",
                        size: "329,849,081 - 387,902,520"
                    },
            //         {
            //             name: "Triathlons (Athletics)",
            //             size: "95,150,246 - 111,896,690"
            //         },
                    {
                        name: "Volleyball (Sport)",
                        size: "342,179,787 - 402,403,430"
                    }
                ]
            }
        ]
    },
    // {
    //     name: "Technology (Computers & Electronics)",
    //     children: [
    //         {
    //             name: "Computers (Computers & Electronics)",
    //             children: [
    //                 {
    //                     name: "Computer Memory (Computer Hardware)",
    //                     size: "34,725,485 - 40,837,171"
    //                 },
    //                 {
    //                     name: "Computer Monitors (Computer Hardware)",
    //                     size: "145,069,931 - 170,602,240"
    //                 },
    //                 {
    //                     name: "Computer Processors (Computer Hardware)",
    //                     size: "186,229,379 - 219,005,750"
    //                 },
    //                 {
    //                     name: "Computer Servers (Computing)",
    //                     size: "87,445,790 - 102,836,250"
    //                 },
    //                 {
    //                     name: "Desktop Computers (Consumer Electronics)",
    //                     size: "155,917,219 - 183,358,650"
    //                 },
    //                 {
    //                     name: "Free Software (Software)",
    //                     size: "561,457,967 - 660,274,570"
    //                 },
    //                 {
    //                     name: "Hard Drives (Computer Hardware)",
    //                     size: "122,281,267 - 143,802,770"
    //                 },
    //                 {
    //                     name: "Network Storage (Computers & Electronics)",
    //                     size: "18,677,605 - 21,964,864"
    //                 },
    //                 {
    //                     name: "Software (Computers & Electronics)",
    //                     size: "925,583,622 - 1,088,486,340"
    //                 },
    //                 {
    //                     name: "Tablet Computers (Computers & Electronics)",
    //                     size: "512,619,285 - 602,840,280"
    //                 }
    //             ]
    //         },
    //         {
    //             name: "Consumer Electronics (Computers & Electronics)",
    //             children: [
    //                 {
    //                     name: "Audio Equipment (Electronics)",
    //                     size: "43,983,308 - 51,724,371"
    //                 },
    //                 {
    //                     name: "Camcorders (Consumer Electronics)",
    //                     size: "18,698,377 - 21,989,292"
    //                 },
    //                 {
    //                     name: "Cameras (Photography)",
    //                     size: "435,776,598 - 512,473,280"
    //                 },
    //                 {
    //                     name: "E-book Readers (Consumer Electronics)",
    //                     size: "45,626,557 - 53,656,832"
    //                 },
    //                 {
    //                     name: "GPS Devices (Consumer Electronics)",
    //                     size: "24,952,948 - 29,344,667"
    //                 },
    //                 {
    //                     name: "Mobile Phones (Smart Phone)",
    //                     size: "1,007,046,692 - 1,184,286,910"
    //                 },
    //                 {
    //                     name: "Portable Media Players (Audio Equipment)",
    //                     size: "6,968,758 - 8,195,260"
    //                 },
    //                 {
    //                     name: "Projectors (Consumer Electronics)",
    //                     size: "31,618,071 - 37,182,852"
    //                 },
    //                 {
    //                     name: "Smartphones (Consumer Electronics)",
    //                     size: "789,714,659 - 928,704,440"
    //                 },
    //                 {
    //                     name: "Televisions (Consumer Electronics)",
    //                     size: "1,077,339,829 - 1,266,951,640"
    //                 }
    //             ]
    //         }
    //     ]
    // }
];

export default categories;