import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { useCredits } from './CreditContext';
import api from './api'; // Import your API utility

const VideoGenerationContext = createContext();

// Create a default initial state
const initialState = {
    selectedInterests: [],
    videoCards: [],
    analyzing: false,
    sourceVideo: null,
    selectedModel: localStorage.getItem('preferredVideoModel') || 'runway' // Load from localStorage with fallback
};

export const VideoGenerationProvider = ({ children }) => {
  // Initialize state with default values
  const [selectedInterests, setSelectedInterests] = useState(initialState.selectedInterests);
  const [videoCards, setVideoCards] = useState(initialState.videoCards);
  const [analyzing, setAnalyzing] = useState(initialState.analyzing);
  const [sourceVideo, setSourceVideo] = useState(initialState.sourceVideo);
  const [selectedModel, setSelectedModel] = useState(initialState.selectedModel);
  const { updateCreditsAfterGeneration } = useCredits();

  // Add state for credits
  const [credits, setCredits] = useState({
    total: 0,
    used: 0,
    remaining: 0
  });

  // Function to fetch updated credit information
  const fetchCredits = async () => {
    try {
      const response = await api.getUserCredits();
      setCredits({
        total: response.data.totalCredits,
        used: response.data.creditsUsed,
        remaining: response.data.totalCredits - response.data.creditsUsed
      });
    } catch (error) {
      console.error('Error fetching credits:', error);
    }
  };

  // Call this in useEffect to load initial credits
  useEffect(() => {
    fetchCredits();
  }, []);

  // Create a ref for the success audio
  const successAudioRef = useRef(null);
  
  // Initialize the audio element
  useEffect(() => {
    successAudioRef.current = new Audio('/success-sound.mp3');
    successAudioRef.current.volume = 0.7; // Set volume to 70%
  }, []);

  // Function to play success sound
  const playSuccessSound = () => {
    if (successAudioRef.current) {
      // Reset the audio to the beginning if it's already playing
      successAudioRef.current.currentTime = 0;
      successAudioRef.current.play().catch(e => console.error("Error playing audio:", e));
    }
  };
  
  // Load state from localStorage on initial mount
  useEffect(() => {
    try {
      const savedState = localStorage.getItem('videoGenerationState');
      if (savedState) {
        const parsedState = JSON.parse(savedState);
        setSelectedInterests(parsedState.selectedInterests || []);
        setVideoCards(parsedState.videoCards || []);
        setAnalyzing(parsedState.analyzing || false);
        setSelectedModel(parsedState.selectedModel || 'runway');
        
        // Resume polling for any in-progress generations
        if (parsedState.videoCards) {
          parsedState.videoCards.forEach((card, index) => {
            if (card.isGenerating && card.taskId) {
              pollTaskStatus(card.taskId, index);
            }
          });
        }
      }
    } catch (error) {
      console.error('Error loading saved state:', error);
    }
  }, []);
  
  // Save state to localStorage whenever it changes
  useEffect(() => {
    try {
      const stateToSave = {
        selectedInterests,
        videoCards,
        analyzing,
        selectedModel
      };
      localStorage.setItem('videoGenerationState', JSON.stringify(stateToSave));
    } catch (error) {
      console.error('Error saving state:', error);
    }
  }, [selectedInterests, videoCards, analyzing, selectedModel]);
  
  // Handle interest selection
  const handleInterestSelect = (interest) => {
    // Check if interest is already selected (by name)
    if (selectedInterests.some(i => i.name === interest.name)) {
      // Remove interest
      setSelectedInterests(selectedInterests.filter(i => i.name !== interest.name));
      
      // Remove corresponding video card
      setVideoCards(videoCards.filter(card => card.interest.name !== interest.name));
    } else {
      // Check if we've reached the maximum of 5 interests
      if (selectedInterests.length >= 5) {
        alert('You can select a maximum of 5 interests');
        return;
      }
      
      // Add interest
      setSelectedInterests([...selectedInterests, interest]);
      
      // Add new video card
      setVideoCards([...videoCards, {
        interest: interest,
        prompt: ``,
        generatedVideoUrl: null,
        isGenerating: false,
        error: null,
        taskId: null
      }]);
    }
  };
  
  // Update card prompt
  const updateCardPrompt = (index, newPrompt) => {
    const updatedCards = [...videoCards];
    updatedCards[index].prompt = newPrompt;
    setVideoCards(updatedCards);
  };
  
  // Generate video for a specific card - UPDATED to match your previous implementation
  const generateVideo = async (index) => {
    if (!videoCards[index] || !sourceVideo) return;
    
    const card = videoCards[index];
    
    // Check if we have enough credits
    const creditCost = selectedModel === 'runway' ? 40 : 80;
    
    // Update card status
    const updatedCards = [...videoCards];
    updatedCards[index].isGenerating = true;
    updatedCards[index].error = null;
    setVideoCards(updatedCards);
    
    try {
      // Create form data for the API request - matching your previous implementation
      const formData = new FormData();

      // Determine if it's a video or image and use appropriate field name
      const isVideo = sourceVideo.type.startsWith('video/');
      if (isVideo) {
        formData.append('sourceVideo', sourceVideo);
      } else {
        formData.append('sourceImage', sourceVideo);
      }
      
      // Add the interest as a JSON array (to match your previous code)
      const interestName = card.interest.name;
      formData.append('interests', JSON.stringify([interestName]));
      
      // Add the prompt
      formData.append('prompt', card.prompt);
      
      // Add model information
      if (selectedModel === 'runway') {
        formData.append('model', 'runway');
      } else if (selectedModel === 'luma-photon') {
        formData.append('model', 'luma-photon');
      } else if (selectedModel.startsWith('luma-')) {
        formData.append('model', 'luma');
        formData.append('lumaModel', selectedModel.replace('luma-', ''));
      } else {
        formData.append('model', selectedModel);
      }
      
      console.log('Sending request with:', {
        fileSize: sourceVideo.size,
        fileName: sourceVideo.name,
        fileType: sourceVideo.type,
        isVideo: isVideo,
        interest: interestName,
        prompt: card.prompt,
        model: selectedModel
      });
      
      // Use your API utility instead of direct axios calls
      const response = await api.transformVideo(formData);
      const taskId = response.data.taskId;
      
      // Update the card with the task ID and isFromVideo flag
      updatedCards[index].taskId = taskId;
      updatedCards[index].isFromVideo = isVideo;
      setVideoCards(updatedCards);
      
      // Start polling for task status
      pollTaskStatus(taskId, index);
      
      // Fetch updated credit information from the server
      fetchCredits();
      
    } catch (error) {
      console.error('Error generating video:', error);
      
      // Parse the error message to provide more context - matching your previous implementation
      let errorMessage = 'Error generating video. Please try again.';
      
      if (error.response && error.response.data) {
        const errorData = error.response.data;
        
        if (errorData.error && errorData.error.includes('Invalid asset aspect ratio')) {
          errorMessage = 'The image has an invalid aspect ratio. Please use an image with width/height ratio between 0.5 and 2.';
        } else if (errorData.error) {
          errorMessage = errorData.error;
        }
      } else if (error.message) {
        errorMessage = error.message;
      }
      
      // Update card with error
      updatedCards[index].isGenerating = false;
      updatedCards[index].error = errorMessage;
      setVideoCards(updatedCards);
    }
  };
  
  // Generate all videos
  const generateAllVideos = async () => {
    if (!sourceVideo) return;
    
    // Check if we have any cards to generate
    const cardsToGenerate = videoCards.filter(card => !card.generatedVideoUrl && !card.isGenerating);
    if (cardsToGenerate.length === 0) return;
    
    // Set analyzing to true
    setAnalyzing(true);
    
    // Generate each video
    const promises = cardsToGenerate.map((card, cardIndex) => {
      const index = videoCards.findIndex(c => c.interest.name === card.interest.name);
      return generateVideo(index);
    });
    
    // Wait for all videos to be generated
    await Promise.all(promises);
  };
  
  // Poll for task status - UPDATED to match your previous implementation
  const pollTaskStatus = async (taskId, index) => {
    // Poll every 5 seconds until task completes (matching your previous implementation)
    const interval = setInterval(async () => {
      try {
        // Use your API utility instead of direct axios calls
        const statusResponse = await api.checkTransformVideoStatus(taskId);
        const { status, videoUrl, error, isFromVideo } = statusResponse.data;

        console.log('Task status update:', { 
          taskId, 
          status, 
          videoUrl, 
          isFromVideo 
        });
        
        if (status === 'completed' && videoUrl) {
          // Clear the interval
          clearInterval(interval);
          
          // Update card with video URL
          const updatedCards = [...videoCards];
          updatedCards[index].isGenerating = false;
          updatedCards[index].generatedVideoUrl = videoUrl;
          updatedCards[index].isFromVideo = isFromVideo;

          console.log('Updated card:', updatedCards[index]);

          setVideoCards(updatedCards);
          
          // Play success sound
          playSuccessSound();
          
          // Check if all videos are done generating
          const stillGenerating = videoCards.some(card => card.isGenerating);
          if (!stillGenerating) {
            setAnalyzing(false);
          }
        } else if (status === 'failed' || error) {
          // Clear the interval
          clearInterval(interval);
          
          // Update card with error
          const updatedCards = [...videoCards];
          updatedCards[index].isGenerating = false;
          updatedCards[index].error = error || 'Video generation failed';
          setVideoCards(updatedCards);
          
          // Check if all videos are done generating
          const stillGenerating = videoCards.some(card => card.isGenerating);
          if (!stillGenerating) {
            setAnalyzing(false);
          }
        }
        // If still processing, continue polling
      } catch (error) {
        console.error('Error checking status:', error);
        // Don't clear the interval on network errors, just log and continue polling
      }
    }, 5000); // Check every 5 seconds (matching your previous implementation)
    
    // Store interval ID in a global object so it persists between renders
    if (!window.taskIntervals) window.taskIntervals = {};
    window.taskIntervals[taskId] = interval;
    
    // Clean up function
    return () => {
      if (window.taskIntervals && window.taskIntervals[taskId]) {
        clearInterval(window.taskIntervals[taskId]);
        delete window.taskIntervals[taskId];
      }
    };
  };
  
  // Download video
  const downloadVideo = async (url, filename) => {
    try {
      // Fetch the video as a blob
      const response = await fetch(url);
      const blob = await response.blob();
      
      // Create a blob URL
      const blobUrl = URL.createObjectURL(blob);
      
      // Create a download link
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = filename;
      
      // Trigger the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      // Clean up the blob URL
      URL.revokeObjectURL(blobUrl);
      
      return true;
    } catch (error) {
      console.error('Error downloading video:', error);
      return false;
    }
  };
  
  // Set source video
  const setVideo = (video) => {
    setSourceVideo(video);
  };
  
  // Set model
  const setModel = (model) => {
    setSelectedModel(model);
  };
  
  return (
    <VideoGenerationContext.Provider value={{
      selectedInterests,
      videoCards,
      analyzing,
      sourceVideo,
      selectedModel,
      credits, // Add credits to the context
      handleInterestSelect,
      updateCardPrompt,
      generateVideo,
      generateAllVideos,
      downloadVideo,
      setVideo,
      setModel,
      fetchCredits, // Add the function to refresh credits
    }}>
      {children}
    </VideoGenerationContext.Provider>
  );
};

export const useVideoGeneration = () => useContext(VideoGenerationContext);