import React from 'react';
import { Calendar } from 'react-feather';
import { useDarkMode } from './DarkModeContext';

const ContentCalendar = () => {
  const { darkMode } = useDarkMode();

  return (
        <div className={`content-calendar-container ${darkMode ? 'dark-mode' : ''}`} style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80vh',
            textAlign: 'center',
            padding: '20px'
        }}>
        <div style={{
            background: darkMode ? '#27292D' : 'white',
            borderRadius: '50%',
            width: '120px',
            height: '120px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '30px'
        }}>
            <Calendar size={60} color={darkMode ? 'white' : '#333'} />
        </div>
        
        <h1 style={{ marginBottom: '20px'}}>Content Calendar</h1>
        
        <p style={{ 
            fontSize: '18px', 
            maxWidth: '600px', 
            lineHeight: '1.6',
            color: '#666',
            marginBottom: '30px'
        }}>
            We're working on an exciting new feature that will help you plan and generate a full week of content for your marketing campaigns.
        </p>
        
        <div style={{
            padding: '20px',
            borderRadius: '8px',
            maxWidth: '600px',
            background: darkMode ? '#27292D' : 'white',
            boxShadow: '0 2px 10px rgba(0,0,0,0.05)'
        }}>
            <h3 style={{ marginBottom: '15px' }}>Coming Soon Features:</h3>
            <ul style={{ 
            textAlign: 'left', 
            lineHeight: '1.8',
            paddingLeft: '20px'
            }}>
            <li>AI-powered content scheduling</li>
            <li>Automated video generation for each day of the week</li>
            <li>Theme-based content planning</li>
            <li>Direct publishing to social media platforms</li>
            <li>Performance analytics and insights</li>
            </ul>
        </div>
        </div>
    );
};

export default ContentCalendar;