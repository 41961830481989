import React from 'react';

const ConfidentialityRules = () => {
    return (
        <div style={{ margin: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
            <h1 style={{ color: '#333' }}>Confidentiality and Data Privacy Policy</h1>
            <p>This policy outlines our commitment to safeguarding user data, including data accessed via Google API Services and Meta Marketing API. It ensures compliance with Google's API Services User Data Policy, Meta's Platform Terms, and establishes guidelines for secure, transparent, and limited data handling.</p>
            <hr />
            <h2>1. Purpose and Scope</h2>
            <p>This Confidentiality Policy governs the handling of sensitive user data, including but not limited to data obtained via Google API Services and Meta Marketing API. Our approach prioritizes transparency, limited use, and secure handling to protect user data and comply with industry standards.</p>
            <hr />
            <h2>2. Use of Google Data</h2>
            <p>We utilize Google API Services to improve user experience and provide seamless account functionality:</p>
            <ul>
                <li><strong>Identification:</strong> Authenticate accounts and identify users.</li>
                <li><strong>User Profiles:</strong> Create usernames and associate profile pictures.</li>
                <li><strong>Communication:</strong> Use email addresses for notifications and account recovery.</li>
            </ul>
            <hr />
            <h2>3. Use of Meta Marketing API</h2>
            <p>We integrate with Meta Marketing API to enable the following functionality:</p>
            <ul>
                <li><strong>Content Upload:</strong> Allow users to upload generated videos directly to their Meta Business Suite accounts.</li>
                <li><strong>Ad Account Access:</strong> Access user's ad accounts (with explicit permission) to facilitate content uploads.</li>
                <li><strong>Campaign Creation:</strong> Enable users to initiate campaign creation with their uploaded content.</li>
            </ul>
            <p>All Meta API access is performed with the user's explicit consent and authentication. We do not store Meta access tokens beyond the duration of the specific upload task.</p>
            <hr />
            <h2>4. Transparency in Data Access and Use</h2>
            <ul>
                <li><strong>Accurate Representations:</strong> We clearly disclose the purpose, types of data accessed, and use cases to users during authorization and in our privacy policy.</li>
                <li><strong>Updates and Consent:</strong> If data usage changes, users will be notified and prompted for explicit consent before new use cases or permissions are implemented.</li>
                <li><strong>Meta-Specific Disclosures:</strong> When requesting access to Meta accounts, we clearly explain what permissions are being requested and how they will be used.</li>
            </ul>
            <hr />
            <h2>5. Data Use and Limitations</h2>
            <ul>
                <li><strong>Strictly Limited Use:</strong> User data is used only for user-facing features prominently displayed in our application.</li>
                <li><strong>No Unauthorized Sharing:</strong> We do not sell, transfer, or use data for profiling, advertising, or surveillance purposes.</li>
                <li><strong>Compliance:</strong> All usage adheres to Google's restrictions on sensitive and restricted API scopes and Meta's Platform Terms.</li>
                <li><strong>Meta Content:</strong> We do not store copies of content uploaded to Meta platforms beyond what is necessary for the upload process.</li>
            </ul>
            <hr />
            <h2>6. Minimum Permissions</h2>
            <ul>
                <li><strong>Least Privilege Principle:</strong> Permissions requested are limited to those necessary for app functionality.</li>
                <li><strong>Incremental Authorization:</strong> Data access is requested in context to ensure relevance and user understanding.</li>
                <li><strong>No Excessive Access:</strong> We avoid "future-proofing" requests or asking for unnecessary data.</li>
                <li><strong>Meta Permissions:</strong> We request only the minimum required Meta permissions (ads_management and business_management) needed for video uploads.</li>
            </ul>
            <hr />
            <h2>7. Secure Data Handling</h2>
            <ul>
                <li><strong>Encryption and Access Control:</strong> All data is encrypted during transmission and at rest using industry-standard protocols.</li>
                <li><strong>Restricted Access:</strong> Only authorized personnel can access sensitive data, with all access logged and monitored.</li>
                <li><strong>Compliance with Security Standards:</strong> For sensitive API scopes, we adhere to security assessments and maintain a secure environment.</li>
                <li><strong>Token Security:</strong> Meta access tokens are stored securely, used only for the intended operation, and not persisted beyond their necessary lifetime.</li>
            </ul>
            <hr />
            <h2>8. Data Storage and Deletion</h2>
            <ul>
                <li><strong>Retention Period:</strong> Data is retained only as long as necessary to deliver user-facing features or comply with legal requirements.</li>
                <li><strong>User Control:</strong> Users can request data deletion at any time by sending an email to <strong>boukar . o . sall [at] gmail . com</strong>. Upon request, data is securely and permanently removed.</li>
                <li><strong>Meta Data:</strong> We do not store Meta access tokens or ad account information beyond the duration of the user session.</li>
            </ul>
            <hr />
            <h2>9. Human Data Access</h2>
            <ul>
                <li><strong>Limited Human Access:</strong> Access by personnel is allowed only in these cases:</li>
                <ul>
                    <li>Explicit user consent (e.g., support requests).</li>
                    <li>Security investigations (e.g., abuse or bugs).</li>
                    <li>Legal obligations or aggregated operations under privacy laws.</li>
                </ul>
            </ul>
            <hr />
            <h2>10. Transparency and User Rights</h2>
            <ul>
                <li><strong>Full Visibility:</strong> Users are informed of how data is collected, used, and stored.</li>
                <li><strong>Control Options:</strong> Users can manage, download, or delete their data directly within the app or by contacting support.</li>
                <li><strong>Meta Integration Transparency:</strong> Users are clearly informed when they are being redirected to Meta services or when Meta API calls are being made on their behalf.</li>
            </ul>
            <hr />
            <h2>11. Breach Protocol</h2>
            <ul>
                <li><strong>Rapid Response:</strong> Users are promptly informed of data breaches.</li>
                <li><strong>Risk Mitigation:</strong> Immediate measures are taken to secure data and minimize impact.</li>
                <li><strong>Platform Notification:</strong> In case of breaches affecting platform data, we notify both users and the relevant platform (Google or Meta) as required.</li>
            </ul>
            <hr />
            <h2>12. Confidentiality Agreements</h2>
            <ul>
                <li><strong>Employee and Contractor Agreements:</strong> Personnel and partners must sign confidentiality agreements to safeguard user data.</li>
                <li><strong>Periodic Training:</strong> Regular training reinforces best practices in data privacy and security.</li>
            </ul>
            <hr />
            <h2>13. Compliance with Platform Policies</h2>
            <p>We fully comply with platform policies by ensuring:</p>
            <ul>
                <li><strong>Google API Services User Data Policy:</strong></li>
                <ul>
                    <li>Accurate representations of who we are, what data we access, and why.</li>
                    <li>Minimized permissions and limited use of Google API data.</li>
                    <li>Secure handling of all data and prevention of unauthorized access.</li>
                </ul>
                <li><strong>Meta Platform Terms:</strong></li>
                <ul>
                    <li>Proper disclosure of our app's functionality and data usage.</li>
                    <li>Respect for user privacy and control over their Meta content.</li>
                    <li>Compliance with Meta's Developer Policies and Community Standards.</li>
                    <li>Proper attribution of Meta integration where required.</li>
                </ul>
            </ul>
            <hr />
            <h2>14. Third-Party Integrations</h2>
            <p>Our application integrates with third-party services to enhance functionality:</p>
            <ul>
                <li><strong>Google Services:</strong> For authentication and user profile information.</li>
                <li><strong>Meta Marketing API:</strong> For uploading content to Meta Business Suite.</li>
                <li><strong>AWS S3:</strong> For secure storage of generated content.</li>
            </ul>
            <p>Each integration follows the same principles of data minimization, security, and transparency outlined in this policy.</p>
            <hr />
            <p>For questions or concerns, please contact us at <strong>boukar . o . sall [at] gmail . com</strong>.</p>
        </div>
    );
};

export default ConfidentialityRules;