import React, { createContext, useState, useContext } from 'react';

const WorkspaceContext = createContext();

export const WorkspaceProvider = ({ children }) => {
  // Store all the state from your Workspace component here
  const [sourceVideo, setSourceVideo] = useState(null);
  const [videoPrompt, setVideoPrompt] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [generatedVideoUrl, setGeneratedVideoUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedModel, setSelectedModel] = useState('runway');
  const [showMetaModal, setShowMetaModal] = useState(false);
  
  // Add any other state variables you need to preserve
  
  return (
    <WorkspaceContext.Provider value={{
      sourceVideo, setSourceVideo,
      videoPrompt, setVideoPrompt,
      selectedCategories, setSelectedCategories,
      generatedVideoUrl, setGeneratedVideoUrl,
      isLoading, setIsLoading,
      message, setMessage,
      selectedModel, setSelectedModel,
      showMetaModal, setShowMetaModal,
      // Add other state variables here
    }}>
      {children}
    </WorkspaceContext.Provider>
  );
};

export const useWorkspace = () => useContext(WorkspaceContext);