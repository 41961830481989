import React, { useEffect, useState } from 'react';
import { useVideoGeneration } from './VideoGenerationContext';
import { Zap } from 'react-feather';
import './CreditDisplay.css';
import api from './api';

const CreditDisplay = ({ collapsed }) => {
    const [credits, setCredits] = useState({
        total: 1600,
        used: 0,
        remaining: 1600
    });
      
    useEffect(() => {
        const fetchCredits = async () => {
            try {
            
            const response = await api.getUserCredits();
            console.log('Credit response:', response.data);
            
            if (response.data) {
                setCredits({
                total: response.data.total || 1600,
                used: response.data.used || 0,
                remaining: response.data.total - response.data.used || 1600
                });
            }
            } catch (error) {
            console.error('Error fetching credits:', error);
            // Keep using default values on error
            }
        };
        
        fetchCredits();
    }, []);
    
    if (credits.loading) {
        return (
        <div className={`credit-display ${collapsed ? 'collapsed' : ''}`}>
            <div className="credit-icon">
            <Zap size={16} />
            </div>
            {!collapsed && <div className="credit-loading">Loading...</div>}
        </div>
        );
    }
    
    // Default values if credits are not loaded yet
    const remaining = typeof credits?.remaining === 'number' ? credits.remaining : 0;
    const total = typeof credits?.total === 'number' ? credits.total : 1600;
    const usagePercentage = Math.round((credits.used / credits.total) * 100);
    
    // Determine color based on usage percentage
    let progressColor = '#4caf50'; // Green
    if (usagePercentage > 75) {
        progressColor = '#ff9800'; // Orange
    }
    if (usagePercentage > 90) {
        progressColor = '#f44336'; // Red
    }
    
    return (
        <div className={`credit-display ${collapsed ? 'collapsed' : ''}`}>
        <div className="credit-icon">
            <Zap size={collapsed ? 12 : 16} />
        </div>
        
        {!collapsed ? (
            <div className="credit-info">
            <div className="credit-text">
                <span className="credit-remaining">{remaining}</span>
                <span className="credit-total" style={{overflow: 'hidden'}}>/{total} credits</span>
            </div>
            
            <div className="credit-progress-container">
                <div 
                className="credit-progress-bar" 
                style={{ 
                    width: `${usagePercentage}%`,
                    backgroundColor: progressColor
                }}
                />
                <span className="credit-percentage">{usagePercentage}% used</span>
            </div>
            </div>
        ) : (
            <div className="credit-mini">
            <span className="credit-mini-text">{remaining}</span>
            </div>
        )}
        </div>
    );
};

export default CreditDisplay;