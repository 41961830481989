import axios from 'axios';
import { isTokenExpired } from './utils/jwt';

// Create a separate instance for unauthenticated API calls
const analyzeApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'https://proto-backend-d2c1638486b9.herokuapp.com',
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    withCredentials: false
});

// Main API instance for authenticated calls
const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'https://proto-backend-d2c1638486b9.herokuapp.com'
});

// Add request interceptor to include token
api.interceptors.request.use(config => {
    // Skip token check for analyze-text endpoint
    if (config.url === '/api/analyze-text') {
        return config;
    }

    const token = localStorage.getItem('token');
    if (token && !isTokenExpired(token)) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// Add response interceptor for debugging
api.interceptors.response.use(
    response => {
        console.log('Response received:', response.status);
        return response;
    },
    error => {
        // Only redirect for authenticated routes
        if (error.response?.status === 401 && !error.config.url.includes('/api/analyze-text')) {
            console.log('Token expired or invalid, redirecting to landing page');
            localStorage.removeItem('token');
            window.location.href = '/';
        }
        console.error('Response error:', error.response?.status);
        return Promise.reject(error);
    }
);

// API methods
const apiMethods = {
    // Unauthenticated routes
    analyzeText: (data) => analyzeApi.post('/api/analyze-text', { 
        text: data.text,
        personName: data.personName 
    }),

    analyzeShortText: (data) => analyzeApi.post('/api/analyze-short-text', { 
        text: data.text,
        personName: data.personName 
    }),

    createCheckoutSessionForAnalysis: () => api.post('/api/create-checkout-session-for-analysis'),
    checkPaymentStatus: (data) => api.post('/api/check-payment-status', data),

    // Profile routes
    getUserProfile: () => api.get('/api/user-profile'),
    updateProfile: (profileData) => api.post('/api/update-profile', profileData),
    updateProspectingProfile: (prospectingProfile) => api.post('/api/update-prospecting-profile', prospectingProfile),
    markVisited: () => api.post('/api/mark-visited'),
    getUserFiles: () => api.get('/api/files'),
    getVoices: () => api.get('/api/voices'),

    // Voice related routes
    generateSpeech: (text, voiceId, prospectId, forceNew = false) => 
        api.post('/api/text-to-speech', { text, voiceId, prospectId, forceNew }),
    cloneVoice: (formData) => api.post('/api/clone-voice', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    }),
    testClonedVoice: (text, voiceId) => api.post('/api/test-cloned-voice', { text, voiceId }),

    // File and video handling routes
    saveVideos: (formData) => api.post('/api/save-videos', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
        timeout: 120000
    }),
    getUserVideos: () => api.get('/api/user/videos'),
    saveFile: (formData) => api.post('/api/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    }),

    // LinkedIn prospects routes
    scrapeLinkedInProfile: (linkedinUrl, userName, userPicture) => 
        api.post('api/prospects/scrape-profile', { linkedinUrl, userName, userPicture }),
    getLinkedInProspects: () => api.get('/api/prospects'),
    deleteProspect: (prospectId) => api.delete(`/api/prospects/${prospectId}`),
    generatePitch: (prospectId, isTextOnly, language) => 
        api.post(`/api/prospects/generate-pitch/${prospectId}`, { isTextOnly, language }),
    updateProspectPitch: (prospectId, pitch) => 
        api.patch(`/api/prospects/${prospectId}/pitch`, { pitch }),
    extractLinkedInInfo: (formData) => api.post('/api/prospects/extract-linkedin-info', formData, {
        headers: { 'Accept': 'application/json' },
        timeout: 30000,
        maxBodyLength: Infinity,
        maxContentLength: Infinity
    }),

    // S3 and upload related routes
    getUploadUrl: (fileType, videoCategory) => 
        api.post('/api/get-upload-url', { fileType, videoCategory }),
    uploadToS3: async (url, file) => {
        try {
            return await axios.put(url, file, {
                headers: { 'Content-Type': file.type },
                maxContentLength: Infinity,
                maxBodyLength: Infinity
            });
        } catch (error) {
            console.error('S3 upload error:', error.message);
            throw error;
        }
    },
    confirmUpload: (key, videoCategory) => 
        api.post('/api/confirm-upload', { key, videoCategory }),
    deleteVideo: (category) => 
        api.delete(`/api/videos/${category}`),

    // Recording routes
    saveRecording: (formData) => api.post('/api/save-recording', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    }),
    deleteRecording: (recordingId) => api.delete(`/api/recording/${recordingId}`),

    // Video generation routes
    generateVideo: (data) => api.post('/api/generate', data),
    checkVideoStatus: (prospectId) => api.get(`/api/videos/status/${prospectId}`),

    // Payment and promo code routes
    validatePromoCode: (code) => api.post('/api/validate-promo-code', { code }),
    createPortalSession: () => api.post('/api/create-portal-session'),
    createCheckoutSession: () => api.post('/api/create-checkout-session'),

    // Runway routes
    transformVideo: (formData) => api.post('/api/transform-video', formData, {
        // headers: { 'Content-Type': 'multipart/form-data' }, // Don't set Content-Type header - let axios set it with the boundary
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
        timeout: 300000 // 5 minutes timeout
    }),
    
    // Check the status of a video generation task
    checkTransformVideoStatus: (taskId) => api.get(`/api/transform-video/status/${taskId}`),

    // New method for stitching videos
    stitchVideo: (formData) => api.post('/api/stitch-video', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    }),

    // Get user credits
    getUserCredits: () => api.get('/api/user/credits'),
    addCredits: (data) => api.post('/api/user/credits/add', data)

};

// Assign all methods to the api object
Object.assign(api, apiMethods);

export default api;